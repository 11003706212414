import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import BaseList from '../BaseList';
import I18n from '../../common/I18n';
import { Radio, Icon, Input, Select, Row, Col, FormItem, Checkbox, Upload, message } from '../../common/UIComponents';
import DateTimeSelector from '../../common/DateTimeSelector';
import { isEmpty } from '../../common/Common';
import { dummyRequest } from '../../helpers/common';
import { TableImageColumn } from '../../common/TableImageColumn';
import DeleteIcon from '../common/DeleteIcon';
import TypeOfOrder from './TypeOfOrder';
// import TypeOfOrder from './TypeOfOrder';

class StatusFormList extends BaseList {
  constructor(props) {
    super(props);
    this.state = {
        isMilitaryTime : this.props.isMilitaryTime,
        completedInfo : this.props.completedInfo,
        selectedOrders: [],
        data : this.props.data,
        bulkOrdersBackupData : this.props.bulkOrdersBackupData,
        viewPodUrl: null,
        activeUploadIndex: null,
        viewPodUrls: {},
        editPodIndex: null,
    }

    this.columns = [];

    
    const titleData = [];

    const otherData = [
      {
        title: "",
        key: "index",
        render: (text, record) => {
          const isSelected = this.state.selectedOrders.includes(record.location_id);
          const isDisabled = record.location_status === "COMPLETED";

          return (
            <Checkbox
              onChange={(e) => this.handleCheckboxChange(e, record)}
              checked={isSelected}
              disabled={isDisabled}
            />
          );
        },
        fixed :'left',
        width: 30,
      },
      // {
      //   title: "",
      //   key: "index",
      //   render: (text, record) => {
      //     const { checked, indeterminate } = this.getCheckboxState(record);
      //     const isDisabled = record.location_status === "COMPLETED";
      //     return (
      //       <Checkbox
      //         onChange={(e) => this.handleCheckboxChange(e, record)}
      //         checked={checked}
      //         indeterminate={indeterminate}
      //         disabled={isDisabled}
      //       />
      //     );
      //   },
      //   width: 55,
      // },
      {
        key: "customer_order_number",
        title: "Order No",
        dataIndex: "customer_order_number",
        render: (text, record) => {
          const type_of_loc =
            record?.type_of_loc === "DELIVERY" || record.type_of_loc === "D" || record.type_of_order === "D"
              ? "D"
              : "P";
          return (
            <div>
              <TypeOfOrder
                order={record}
                orderTypeKey="type_of_order"
                relatedOrderKey="related_order"
                placement="topLeft"
                showBadge={ true }
              />&nbsp;
              {`${text}(${type_of_loc})`}
            </div>
          );
        },
        width:90,
     },
      {
        key: "driver_id",
        title: (
          <div>
            <sup className="textRed">*</sup> {" "}
            {"Driver"}
          </div>
        ),
        dataIndex: "driver_id",
        render: (text, record, index) => {
          const shouldDisable = this.shouldDisableFields(record);
          return (
            <Select
              showSearch
              style={{ width: "100%" }}
              filterOption={(input, option) => {
                const children = option.props.children;
                if (children && children.length > 0) {
                  if (Array.isArray(children)) {
                    return children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  } else {
                    return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }
                }
              }}
              onChange={(e) => this.props.handleDetailsChange(index, "driver_id", e)}
              value={record.driver_id || _.get(record,"driver_details[0].id", "")}
              size="small"
              dropdownMatchSelectWidth={false}
              disabled={shouldDisable}
            >
              <Select.Option key="DRIVER" value="">
                {" "}
                -- Select --{" "}
              </Select.Option>
              {this.props.organizationSettings?.remove_default_driver === "true" && (
              <Select.Option key={"default_driver"} value={"default_driver"}>
                Default Driver
              </Select.Option>
              )}
              {this.props?.drivers?.map((driverDetail) => (
                <Select.Option key={driverDetail.id} value={driverDetail.id}>
                  {driverDetail.employee_code}
                </Select.Option>
              ))}
            </Select>
          );
        },
        width: 120,
      },
      {
        key: "sign_by",
        title: "Signed By",
        dataIndex: "sign_by",
        render: (text, record, index) => {
          const shouldDisable = this.shouldDisableFields(record);
          return (
            <Input
              value={record.sign_by || ""}
              maxLength={40}
              size="small"
              onChange={(e) =>
                this.props.handleDetailsChange(index, "sign_by", e.target.value)
              }
              disabled={shouldDisable}
              required
            />
          );
        },
        width:105,
      },
      {
        key: "signer_title",
        title: "Signed Title",
        dataIndex: "signer_title",
        render: (text, record, index) => {
          const shouldDisable = this.shouldDisableFields(record);
          return (
            <Input
              type="text"
              value={record.signer_title || ""}
              size="small"
              onChange={(e) =>
                this.props.handleDetailsChange(index, "signer_title", e.target.value)
              }
              disabled={shouldDisable}
              required
            />
          );
        },
        width:105,
      },
      {
        key: "completed_date",
        title: (
          <div>
            <sup className="textRed">*</sup> {" "}
            {"Completed Date"}
          </div>
        ),
        dataIndex: "completed_date",
        render: (text, record, index) => {
          const shouldDisable = this.shouldDisableFields(record);
          return this.props.renderDateTimeField(
            record,
            false,
            "Completed Time",
            "completed_date",
            true,
            false,
            false,
            false,
            index,
            false,
            shouldDisable,
            "list"
          );
        },
        width:105,
      },
      {
        key: "captured_at",
        title: "Captured Time",
        dataIndex: "captured_at",
        render: (text, record, index) => {
          const shouldDisable = this.shouldDisableFields(record);
          return this.props.renderDateTimeField(
            record,
            "Captured Date",
            "",
            "captured_at",
            false,
            true,
            false,
            record.nsr,
            index,
            false,
            shouldDisable,
            "list"
          );
        },
        width:102,
      },
      {
        key: "actual_start_datetime",
        title: (
          <div>
            <sup className="textRed">*</sup> {" "}
            {"Arrival time"}
          </div>
        ),
        dataIndex: "actual_start_datetime",
        render: (text, record, index) => {
          const shouldDisable = this.shouldDisableFields(record);
          return this.props.renderDateTimeField(
            record,
            "Arrival Date",
            "",
            "actual_start_datetime",
            false,
            true,
            false,
            false,
            index,
            false,
            shouldDisable,
            "list"
          );
        },
        width:102,
      },
      {
        key: "actual_end_datetime",
        title: (
          <div>
            <sup className="textRed">*</sup> {" "}
            {"Dept Time"}
            
          </div>
        ),
        dataIndex: "actual_end_datetime",
        render: (text, record, index) => {
          const shouldDisable = this.shouldDisableFields(record);
          return this.props.renderDateTimeField(
            record,
            "Dept. Date",
            "",
            "actual_end_datetime",
            false,
            true,
            false,
            false,
            index,
            false,
            shouldDisable,
            "list"
          );
        },
        width:102,
      },
      {
        key : "pod",
        title: "POD",
        dataIndex: "pod",
        render : (text,record,index) => {
          const isPictureAvailable = Boolean(record?.pod?.picture);
            return (
              <div className='podtable'>
              {record?.pod?.src || record?.pod?.picture?.url ? 
            <div style={{ display: 'flex', alignItems: 'center' }}> 
            <TableImageColumn
                record={record?.pod?.src || record?.pod?.picture?.url}
                width="60"
                height="30"
                renderType='icon'
              /> &nbsp;&nbsp;
              {record?.pod?.src && (
              <DeleteIcon
              styleObj={{ cursor: 'pointer', marginTop: '0px',fontSize:'15px' }}
              handleClick={() => this.props.handlePodDelete(index)}
            />
              )}
              </div>
            :
              <Upload
              showUploadList={false}
              beforeUpload={(file) => {
                this.props.handleDetailsChange(index, "pod", file)
                return false;
              }}
              multiple={false}
              customRequest={dummyRequest}
              disabled={isPictureAvailable}
            >
              <Icon type="link" 
              style={{ cursor: 'pointer',color:"red",transform: 'rotate(45deg)',fontSize : "20px",paddingTop:'4px',paddingLeft: '4px' }}/>
            </Upload>
        }
            </div>
            )
        },
        width:50,
        fixed :'right'
      },
    ];

this.columns = [...titleData, ...otherData];
    this.columns = [...titleData, ...otherData];
  }

  componentDidUpdate(prevProps,prevState) {
    if (!_.isEqual(prevProps.selectedOrders, this.props.selectedOrders)) {
      this.setState({ selectedOrders: this.props.selectedOrders });
    }

    if (!_.isEqual(prevState.selectedOrders, this.state.selectedOrders)) {
      this.props.updateSelectedLocations(this.state.selectedOrders);
    }
  }
  shouldDisableFields(record) {
    if (record.location_status === "COMPLETED") {
      return true;
    }
  }

  handleViewPod = (index, url) => {
    this.setState((prevState) => ({
      viewPodUrls: {
        ...prevState.viewPodUrls,
        [index]: url
      }
    }));
  };
  
  // getCheckboxState(record) {
  //   const hasDriver = Boolean(record.driver_id || _.get(record, "driver_details[0].id", ""));
  //   const hasPod = Boolean(record?.pod?.src || record?.pod?.picture?.url);
  
  //   // Determine states
  //   if (hasDriver && hasPod) {
  //     return { checked: true, indeterminate: false }; // Fully checked
  //   } else if (hasDriver || hasPod) {
  //     return { checked: false, indeterminate: true }; // Partial check
  //   } else {
  //     return { checked: false, indeterminate: false }; // Unchecked
  //   }
  // }

  handleCheckboxChange = (e, record) => {
    const isChecked = e.target.checked;
  
    this.setState((prevState) => {
      let updatedSelected = [...prevState.selectedOrders];
      if (["R","D"].includes(record.type_of_order)  ) {
        if (isChecked) {
          updatedSelected.push(record.location_id);
        } else {
          updatedSelected = updatedSelected.filter(
            (location_id) => location_id !== record.location_id
          );
        }
      } else {
      if (isChecked) {
        updatedSelected.push(record.location_id);
        if (record.type_of_order === "LH" && (record.type_of_loc === "PICKUP" || record.type_of_loc === "DELIVERY")) {
          const relatedLHLocations = this.state.data.filter(
            (item) =>
              item.order_id === record.order_id &&
              (item.type_of_loc === "PICKUP" || item.type_of_loc === "DELIVERY") &&
              item.location_status !== "COMPLETED"
          );
          relatedLHLocations.forEach((item) => updatedSelected.push(item.location_id));
  
          const relatedTPickups = this.state.data.filter(
            (item) =>
              item.type_of_order === "T" &&
              item.type_of_loc === "PICKUP" &&
              item.order_id === record.related_transfer_id &&
              item.location_status !== "COMPLETED"
          );
          relatedTPickups.forEach((item) => updatedSelected.push(item.location_id));
        }
  
        if (record.type_of_order === "T" && record.type_of_loc === "DELIVERY") {
          const relatedTPickup = this.state.data.find(
            (item) =>
              item.type_of_order === "T" &&
              item.type_of_loc === "PICKUP" &&
              item.order_id === record.order_id &&
              item.location_status !== "COMPLETED"
          );
          if (relatedTPickup) {
            updatedSelected.push(relatedTPickup.location_id);
          }
  
          const relatedLHLocations = this.state.data.filter(
            (item) =>
              item.type_of_order === "LH" &&
              (item.type_of_loc === "PICKUP" || item.type_of_loc === "DELIVERY") &&
              item.related_transfer_id === record.order_id &&
              item.location_status !== "COMPLETED"
          );
          relatedLHLocations.forEach((item) => updatedSelected.push(item.location_id));
        }
      } else {
        if (record.type_of_order === "LH") {
          const relatedLHLocations = this.state.data.filter(
            (item) =>
              item.order_id === record.order_id &&
              (item.type_of_loc === "PICKUP" || item.type_of_loc === "DELIVERY")
          );
          relatedLHLocations.forEach((item) => {
            updatedSelected = updatedSelected.filter((location_id) => location_id !== item.location_id);
          });
        }
  
        if (record.type_of_order === "T") {
          if (record.type_of_loc === "DELIVERY") {
            const relatedLHLocations = []
            // const relatedLHLocations = this.state.data.filter(
            //   (item) =>
            //     item.type_of_order === "LH" &&
            //     (item.type_of_loc === "PICKUP" || item.type_of_loc === "DELIVERY") &&
            //     item.related_transfer_id === record.order_id
            // );
  
            relatedLHLocations.forEach((item) => {
              updatedSelected = updatedSelected.filter((location_id) => location_id !== item.location_id);
            });
  
            updatedSelected = updatedSelected.filter((location_id) => location_id !== record.location_id);
          }
  
          if (record.type_of_loc === "PICKUP") {
            const relatedTDelivery = this.state.data.find(
              (item) =>
                item.type_of_order === "T" &&
                item.type_of_loc === "DELIVERY" &&
                item.order_id === record.order_id
            );
            if (relatedTDelivery) {
              updatedSelected = updatedSelected.filter((location_id) => location_id !== relatedTDelivery.location_id);
            }
            updatedSelected = updatedSelected.filter((location_id) => location_id !== record.location_id);
            }
          }
        }
      }

      return { selectedOrders: [...new Set(updatedSelected)] };
    });
  };
}

export default withRouter(StatusFormList);

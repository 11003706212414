import React, { Fragment, useState } from "react";
import PropTypes from 'prop-types'
import _ from "lodash";
import {isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import {
  Row,
  Col,
  Checkbox,
  Icon,
  Input,
  Popconfirm,
  Radio,
  TextArea,
  Card,
  Tooltip
} from "../../common/UIComponents";
import AppConfig from "../../config/AppConfig";
import { DriverSelect } from "../common/SelectDropdowns/BaseSelect";
import { getOrdinal } from "../../helpers/array_functions";
import DeleteIcon from "../common/DeleteIcon";
import DisplayTime from "../common/DisplayTime";
import masterRateSheet from "../../../assets/images/masterLevelAccessorial.png";
import accLevel from "../../../assets/images/accLevelAccessorial.png";

const RadioGroup = Radio.Group;

const OrderAccessorialRowList = (props) => {
  const { haveMultipleLocations } = props;
  const authorizeDetails = props?.authorizeDetails ? props.authorizeDetails: {};
  const reqFrom = props.reqFrom ? props.reqFrom : 'orders'
  const renderAttemptedDriver = (value, record, orderAccObject, index, ordinalCount) => {
    const isLos = !isEmpty(orderAccObject) && orderAccObject.accessorial_type === "los";
    return (

    <Col xs={haveMultipleLocations ? 12 : 8 } key={`drv${index}`}>
      <Row className="marginBottom5"> 
        <Col xs={10}> {getOrdinal(ordinalCount)}&nbsp;Attempt by 
        {/* {AppConfig.roles.driver} */}
        </Col>
        <Col xs={1}>:</Col>
        <Col xs={13}>
          <DriverSelect
            showSearch
            data={props.drivers}
            value={!isEmpty(value) ? value : ''}
            onChange={e => props.onChange(record.id, 'attempted_driver_ids', e, index)}
            placeholder={I18n.t('location.location')}
            style={{ width: '88%' }}
            selectKey="id"
            selectValue="id"
            selectedValue="employee_code"
            size="small"
              disabled={isEmpty(orderAccObject) || isLos}
            showSelectOption={isEmpty(value) ? true : false}
            dropdownMatchSelectWidth={false}
          />
          {
          index >= 0 && 
          <Fragment>
              &nbsp;
              <Popconfirm
              placement="topRight"
              title={I18n.t("messages.delete_confirm")}
              onConfirm={() => props.onChange(record.id, 'attempted_driver_ids', '', index)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteIcon/>
							&nbsp;&nbsp;&nbsp;
            </Popconfirm>
              
          </Fragment>
          }
        </Col>
      </Row>
      </Col>)
  };

  const processRow = (record, isStandardCode = false) => {
    const authorizeRecord = authorizeDetails && authorizeDetails[record.accessorial_code] ? authorizeDetails[record.accessorial_code] : {};
    const orderAccObject = props.orderAccessorials.find(
      (acc) => acc.account_accessorial_id === record.id
    );
    const isLos =
      !isEmpty(orderAccObject) && orderAccObject.accessorial_type === "los";
    // props.orderAccessorials.map(accessorial => accessorial.account_accessorial_id);
    const isReadOnly = props.isReadOnly;
    let orderComponentParams = [];
    if (!isEmpty(orderAccObject) && !isEmpty(orderAccObject.component_params)) {
      orderComponentParams = orderAccObject.component_params;
    }
    const componentDefinitions =
      !isEmpty(record.componentDefs) &&
      _.isArray(record.componentDefs) &&
      record.componentDefs.length > 0
        ? record.componentDefs.filter((component) =>
            props.displayComponents.includes(component.code)
          )
        : [];
    const prevAttempts = orderAccObject?.driver_pay_params && 
              orderAccObject.driver_pay_params?.attempted_driver_ids ? orderAccObject.driver_pay_params.attempted_driver_ids : [];
    return (
      // <div className="boxShadow paddingTop5 paddingBottom5">
      <Card size="small" bodyStyle={{ padding: 5}} className="marginTop5">
        <Row gutter={16} className="marginTop5 marginBottom5 fontSize13 textBold">
          <Col xs={15} className="fontSize12">
            {!props.isReadOnly ? (
                <Checkbox
                  checked={!isEmpty(orderAccObject)}
                  onChange={
                    isEmpty(record.standard_code)
                      ? (e) =>
                          props.handleAddOrderCompAccessorial(
                            record.id,
                            record.accessorial_code,
                            e.target.checked,
                            record.standard_code === null
                          )
                      : (e) =>
                          props.handleCheckStandardAccessorial(
                            record.id,
                            record.accessorial_code,
                            e.target.checked,
                            record.standard_code === null
                          )
                  }
                  disabled={isLos}
                >
                <Tooltip title={record.rate_sheet_id ? "Master Accessorial" : "Account Accessorial"}>
                  <img
                    src={record.rate_sheet_id ? masterRateSheet : accLevel}
                    alt={record.rate_sheet_id ? "Master Accessorial" : "Account Accessorial"}
                    style={{ height: '20px', width: '20px', marginLeft: '8px', verticalAlign: 'middle' }}
                  />
                </Tooltip>
                &nbsp;&nbsp;
                {record.accessorial_name}  {!isStandardCode && <span>({record.accessorial_code})</span>}
                {record?.accessorial_config_params?.warehouse_operation ===
                "true" ? (
                  <span style={{ fontSize: "15px", color: "#0f85e8" }}>
                   &nbsp;&nbsp;&ndash;&nbsp; Warehouse Operation{" "}
                   {record.accessorial_code && props?.required_accessorials && _.isArray(props.required_accessorials) &&
                   props.required_accessorials.includes(record.accessorial_code) && 
                   <sup className="textRed">*</sup>}
                  </span>
                ) : (
                  ""
                )}
                </Checkbox>
              
            ) : (
              <Fragment>
                {record.accessorial_type === "los" ||
                (!isEmpty(orderAccObject) &&
                  orderAccObject.is_completed === true) ? (
                  <Icon
                    type="check-circle"
                    theme="twoTone"
                    className="textGreen"
                  />
                ) : (
                  <Icon type="check-circle" className="textGrey" />
                )}
                  <Tooltip title={record.rate_sheet_id ? "Master Accessorial" : "Account Accessorial"}>
                    <img
                      src={record.rate_sheet_id ? masterRateSheet : accLevel}
                      alt={record.rate_sheet_id ? "Master Accessorial" : "Account Accessorial"}
                      style={{ height: '20px', width: '20px', marginLeft: '8px', verticalAlign: 'middle' }}
                    />
                  </Tooltip>
                  &nbsp;&nbsp;
                  {record.accessorial_name}  {!isStandardCode && <span>({record.accessorial_code})</span>}
                {record?.accessorial_config_params?.warehouse_operation ===
                "true" ? (
                  <span style={{ fontSize: "15px", color: "#0f85e8" }}>
                   &nbsp;&nbsp; &ndash;&nbsp; Warehouse Operation{" "}
                  </span>
                ) : (
                  ""
                )}
              </Fragment>
            )}
          </Col>
          <Col xs={9} className="fontSize12 alignRight">
                <span className="textCaptilize">
                  { orderAccObject?.created_by ? `by ${orderAccObject?.created_by}` : ''}
                </span>
                 {
                  orderAccObject?.created_at ? 
                    <span> (
                      <DisplayTime
                        dateTimeString={orderAccObject.created_at}
                        displayWithDate={true}
                        isUTC={true}
                        tz_short_name="UTC"
                      />
                    )</span>
                    
                  : ''
                }
          </Col>
      
        </Row>
       
        <Row className="marginTop5">
          <Col xs={24}>
          <Row gutter={16}>
              {record.accessorial_config_params && record.accessorial_config_params.eligible_for && record.accessorial_config_params.eligible_for == 'wh_handling' &&
              <Col xs={haveMultipleLocations ? 12 : 6}>
                <Row>
                  <Col xs={10}>{AppConfig.roles.technician}</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={13}>
                    <DriverSelect
                      showSearch
                      data={props.technicians}
                      value={!isEmpty(orderAccObject) && orderAccObject.driver_pay_params?.driver_id ? orderAccObject.driver_pay_params.driver_id: ''}
                      onChange={e => props.onChange(record.id, 'driver_id', e, 'eligible_for')}
                      placeholder={I18n.t('location.location')}
                      style={{ width: '100%' }}
                      selectKey="id"
                      selectValue="id"
                      selectedValue="employee_code"
                      size="small"
                      disabled={isEmpty(orderAccObject)}
                    />
                  </Col>
                </Row>
              </Col>}
              {/* Start Render attempt details */}
              {reqFrom ==='orders' && record.standard_code === 'ATMP' && !isEmpty(orderAccObject) && 
                prevAttempts &&
                prevAttempts.map((rec, index) => renderAttemptedDriver(rec, record, orderAccObject, index, index + 1))
              }
              {reqFrom ==='orders' && record.standard_code === 'ATMP' &&
                renderAttemptedDriver('', record, orderAccObject, -1, prevAttempts.length+1)
              }
               {/* End Render attempt details */}
              {/* start component details */}
              { componentDefinitions.length > 0 && (
                <Fragment>
                  {componentDefinitions.map((componentDef) => {
                    let paramObject = {};
                    if (
                      componentDef.component_code === "COLLECTION" &&
                      componentDef.component_params.length > 0
                    ) {
                      paramObject = _.find(componentDef.component_params, {
                        accessorial_key: "collection_name",
                      });
                    }

                    const orderComponent = orderComponentParams.find(
                      (component) =>
                        component.component_code === componentDef.code
                    );

                    const orderParamValues =
                      orderComponent && orderComponent.component_values
                        ? orderComponent.component_values
                        : [];
                    let componentParams = componentDef.component_params.filter(
                      (param) => param.visible_to.includes("DISPATCHER")
                    );
                    return (
                      <Fragment>
                        {componentParams.map((paramDefinition) => {
                          const currentParamObj = orderParamValues.find(
                            (cp) => cp.accessorial_key === paramDefinition.code
                          );
                          const isSlotObject = paramDefinition.code === 'SLOT_CONFIG';
                          return (isReadOnly && !isEmpty(currentParamObj)) ||
                            !isReadOnly ? (
                            <Col xs={isSlotObject ? 24 : (haveMultipleLocations ? 12 : 6)} className="fontSize12">
                              <Row>
                                <Col xs={isSlotObject ? 4 : 10}>
                                  {paramDefinition.code === "things_count" &&
                                  !isEmpty(paramObject) &&
                                  paramObject.accessorial_value
                                    ? paramObject.accessorial_value
                                    : paramDefinition.name}
                                </Col>
                                <Col xs={1}>:</Col>
                                <Col xs={isSlotObject ? 19: 13}>
                                  {
                                    isSlotObject  ?
                                    <Fragment>
                                    {!props.isReadOnly
                                          ? props.renderSlotObject(
                                              paramDefinition,
                                              {
                                                ...componentDef,
                                                component_code: componentDef.code,
                                              },
                                              record,
                                              orderAccObject,
                                              currentParamObj
                                            )
                                          : !isEmpty(currentParamObj) &&
                                            !isEmpty(currentParamObj.accessorial_value)
                                          ? currentParamObj.accessorial_value
                                          : "-"}
                                    </Fragment>
                                    :
                                    <Fragment>
                                      {!props.isReadOnly
                                          ? props.renderField(
                                              paramDefinition,
                                              {
                                                ...componentDef,
                                                component_code: componentDef.code,
                                              },
                                              record,
                                              orderAccObject,
                                              currentParamObj
                                            )
                                          : !isEmpty(currentParamObj) &&
                                            !isEmpty(currentParamObj.accessorial_value)
                                          ? currentParamObj.accessorial_value
                                          : "-"}

                                    </Fragment>

                                  }
                                </Col>
                              </Row>
                            </Col>
                          ) : (
                            <Fragment />
                          );
                        })}
                      </Fragment>
                    );
                  })}
                </Fragment>
              )}
              {/* end component details */}
          </Row>
          </Col>
        </Row>
        {/* start authorization details */}
        {
          reqFrom ==='orders' && props.config?.require_accessorial_authorization === 'true' &&
          <Row className="marginTop5" gutter={16}>
            <Col xs={haveMultipleLocations ? 12 : 6}>
              <Row>
                <Col xs={10}><sup className="textRed">*</sup>{I18n.t('accessorials.authorize.by')}</Col>
                <Col xs={1}>:</Col>
                <Col xs={13}>
                <Input 
                  disabled={isEmpty(orderAccObject) || isLos}
                  value={authorizeRecord['authorized_by']} size="small" onChange={(e) => props.handleAuthorizeChange(record.accessorial_code,'authorized_by', e.target.value)}/>
                </Col>
              </Row>
            </Col>
            <Col xs={haveMultipleLocations ? 12 : 6}>
              <Row>
                <Col xs={12}>{I18n.t('accessorials.authorize.code')}</Col>
                <Col xs={1}>:</Col>
                <Col xs={11}>
                  <Input 
                    disabled={isEmpty(orderAccObject) || isLos}
                    value={authorizeRecord['authorization_code']} size="small" onChange={(e) => props.handleAuthorizeChange(record.accessorial_code, 'authorization_code', e.target.value)}/>
                </Col>
              </Row>
            </Col>
            <Col xs={haveMultipleLocations ? 24 : 12} className="marginTop5">
              <Row>
                <Col xs={3}>{I18n.t('accessorials.authorize.comments')}</Col>
                <Col xs={1} style={{ paddingLeft : '5.8px'}}>:</Col>
                <Col xs={20}>
                <TextArea 
                  value={authorizeRecord['comments']}  
                  disabled={isEmpty(orderAccObject) || isLos}
                  size="small" rows={1} onChange={(e) => props.handleAuthorizeChange(record.accessorial_code, 'comments', e.target.value)}autoSize/>
                </Col>
              </Row>
            </Col>
            
          </Row>
        }
        {/* end authorization details */}
      </Card>
    );
  };
  const data = props.data || [];

  const [revisedData,setRevisedData]=useState(data);

  const handleSearchChange = (e) => {
    if(e.target.value=="")
    {
      setRevisedData(data);  
    }else{
      const filteredData=filterData(e.target.value);
      setRevisedData(filteredData);
    }
  };
  

  const filterData = (val) => {
    return (data.filter(item =>
      item.accessorial_name.toLowerCase().includes(val.toLowerCase()) ||
      item.accessorial_code.toLowerCase().includes(val.toLowerCase())
    ));
  };

  return (
    <Fragment>
      {/* <Row className="textBold fontSize13" gutter={16} style={{ height: 30 }}>
        <Col xs={6}>{I18n.t("general.accessorial")} </Col>
        <Col xs={3}>{I18n.t("general.code")} </Col>
        <Col xs={15}></Col>
      </Row> */}
      {
        props.hideSearch ? (
          revisedData.map((record) => (
            <Fragment>
              {processRow(record, false)}
            </Fragment>
          ))
        ) : (
          <>
            <Input
              placeholder="Search Accessorials"
              onChange={handleSearchChange}
              style={{ marginBottom: '10px' }}
            />
            {revisedData.map((record) => (
              <Fragment>
                {processRow(record, false)}
              </Fragment>
            ))}
          </>
        )
      }
      
    </Fragment>
  );
};

export default OrderAccessorialRowList;
OrderAccessorialRowList.propTypes = {
  renderSlotObject: PropTypes.func,
  handleAuthorizeChange: PropTypes.func,
  renderField: PropTypes.func,
  onChange: PropTypes.func,
  data: PropTypes.array,
  isReadOnly: PropTypes.bool,
  technicians: PropTypes.array,
  order: PropTypes.object,
  orderAccessorial: PropTypes.array,
  orderComponentParams: PropTypes.array,
  orderAccessorialParams: PropTypes.array,
  orderAccessorialAttemptedDrivers: PropTypes.array,
  reqFrom: PropTypes.string,
  hideSearch: PropTypes.bool,
};



// default 
OrderAccessorialRowList.defaultProps = {
  renderSlotObject: () => {},
  handleAuthorizeChange: () => {},
  reqFrom: 'orders',
  required_accessorials : [],
  hideSearch: false
};

import axios from "axios/index";
import moment from "moment";
import {
  Call,
  ApiUrl,
  GenerateURL,
  PageParams,
  ParseGeneralResponse,
  setObjectToFormData,
  buildQueryString,
  javaWmsUrl,
  setBinaryFormData,
} from "./ApiUtils";
import AppConfig from "../config/AppConfig";
import { isEmpty } from "../common/Common";
import I18n from "../common/I18n";
import { blobDownload } from "../helpers/common";
import OrdersStats from "../components/orders/OrdersStats";

export function saveOrder (isNew, data) {
  const url = isNew
    ? ApiUrl("v2/customer_orders/change_order_time")
    : ApiUrl(`v2/customer_orders/change_order_time?id=${data.id}`);
  const method = isNew ? "post" : "put";
  return Call(method, url, data, ParseGeneralResponse, {
    responseDataKeys: { order: "customer_order" },
  });
}

export function createOrder(data, isNew) {
  data.operation_code = "CCO";
  const url = isNew
    ? ApiUrl("v2/customer_orders/create_cs_order")
    : ApiUrl(`v2/customer_orders/change_order_time?id=${data.id}`);
  const method = isNew ? "post" : "put";
  return Call(method, url, data, ParseGeneralResponse, {
    responseDataKeys: { orders: "customer_orders" },
  });
}

export function bulkUpload (isNew, data) {
  data.operation_code = 'XMLUO';
  const formData = setObjectToFormData(data);
  const url = ApiUrl("v2/import_orders");
  return Call(
    "POST",
    url,
    formData,
    ParseGeneralResponse,
    {
      responseDataKeys: { orders: "" },
    },
    false
  );
}

export function uploadPod(data) {
  data.operation_code='NRASOP';
  const formData = setObjectToFormData(data);
  const url = ApiUrl("v2/nav_routes/add_stop_order_pictures");
  return Call(
    "PUT",
    url,
    formData,
    ParseGeneralResponse,
    {
      responseDataKeys: { orders: "" },
    },
    false
  );
}

export function saveSettings (data) {
  const formData = setObjectToFormData(data);
  const url = ApiUrl("v2/import_orders");
  return Call(
    "POST",
    url,
    formData,
    ParseGeneralResponse,
    {
      responseDataKeys: { orders: "" },
    },
    true
  );
}

export function fetchLocationOrders (
  locationId,
  locationType = "PICKUP",
  page = "all"
) {
  const url = ApiUrl(
    `v2/customer_orders?page=${page}&task_type=${locationType}&location_id=${locationId}`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { orders: "", pagination: "meta.pagination" },
  });
}

export function fetchTaskOrders (taskId) {
  const url = ApiUrl(`v2/customer_orders/get_orders_in_task?task_id=${taskId}`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { orders: "" },
  });
}

export function ordersByPeriod (data) {
  const searchDate = moment(
    moment(data.startDate).add(-24, "hours"),
    "YYYY-MM-DD"
  ).format("YYYY-MM-DD");
  const url = ApiUrl(
    `v2/customer_orders/weekly_order_schedule?from_date=${searchDate}&warehouse_id=${data.warehouse}&operation_code=WOS`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { orders: "weekly_schedules" },
  });
}

export function fetchOrdersStatus() {
  const url = ApiUrl("v2/customer_orders/order_stats?operation_code=COSTATS");
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: "" },
  });
}

export function fetchOrdersStatusByFilter (
  fromDate,
  todate,
  scheduleDayFilter = scheduleDayFilter || "",
  customerOrderNumber,
  account_codes = [],
  warehouseId = "",
  searchKeyType = "",
  searchKeyValue = "",
  scheduleOrdersType = I18n.t("order.filters.schedule_orders.both_key"),
  globalSearch = false,
  zone_ids = [],
  status = I18n.t("order.default_status"),
  exception_code = [],
  order_type= '',
  vehicle_type = ''
) {
  let url = "";
  const zoneIds = !isEmpty(zone_ids) && zone_ids.length ? zone_ids.map((zone) => zone.value) : [];
  if (customerOrderNumber !== "") {
    url = ApiUrl(
      `v2/customer_orders/filtered_order_stats?customer_order_number=${customerOrderNumber}&global_search=${globalSearch}`
    );
  } else {
    url = ApiUrl(
      `v2/customer_orders/filtered_order_stats?from_date=${fromDate}&to_date=${todate}&schedule_day_filter=${scheduleDayFilter}&account_code=${account_codes}&warehouse_id=${warehouseId}&schedule_orders_type=${scheduleOrdersType}&zone_ids=${zoneIds}&order_type=${order_type}&vehicle_type=${vehicle_type}`
    );
    if (!isEmpty(searchKeyType) && !isEmpty(searchKeyValue)) {
      url = `${url}&${searchKeyType}=${searchKeyValue}`;
    }
    if(!isEmpty(exception_code) && status === "EXCEPTION") {
      url = `${url}&exception_code=${exception_code}`;
    }
  }
  url = `${url}&operation_code=COFS`;
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: "" },
  });
}

export function fetchOrders (data) {
  const {
    status = I18n.t("order.default_status"),
    source,
    fromDate,
    todate,
    scheduleDayFilter = scheduleDayFilter || "Custom",
    unDeliveredOrders = unDeliveredOrders || "none",
    insertionType = insertionType || "",
    page = 1,
    perPage = AppConfig.perPage,
    orderNumber,
    sortBy = "none",
    sortByType = "ascend",
    account_codes = [],
    warehouseId = "",
    searchKeyType = "",
    searchKeyValue = "",
    scheduleOrdersType = I18n.t("order.filters.schedule_orders.both_key"),
    globalSearch = false,
    zone_ids = [],
    order_type = "",
    exception_code = [],
    saveNSearch = false,
    vehicle_type = "",
    created_by_ids = [],
    stats_only = false,
    is_dispatch = false,
    search_type= '',
  } = data;
  let url = "";
  const zoneIds = !isEmpty(zone_ids) &&
  zone_ids.length ? zone_ids.map((zone) => zone.value) : [];
  if (!isEmpty(orderNumber)&& (search_type !== 'CONTAINS')) {
    const encodedValue = encodeURIComponent(orderNumber);
    url = ApiUrl(
      `v2/customer_orders/order_listing_details?customer_order_number=${encodedValue}&global_search=${globalSearch}&${PageParams(
        page,
        perPage
      )}&status=${status}`
    );
    if(is_dispatch){
      url = `${url}&from_date=${fromDate}&to_date=${todate}`
    }
  } else {
    url = ApiUrl(
      `v2/customer_orders/order_listing_details?status=${status}&from_date=${fromDate}&to_date=${todate}&${PageParams(
        page,
        perPage
      )}&schedule_day_filter=${encodeURIComponent(scheduleDayFilter)}&sort_by=${sortBy}&sort_order=${I18n.t(
        `general.sort_${sortByType}&save_search_history=${saveNSearch}`
      )}&account_code=${account_codes}&schedule_orders_type=${scheduleOrdersType}&zone_ids=${zoneIds}&order_type=${order_type}&vehicle_type=${vehicle_type}&created_by_ids=${created_by_ids}&source=${source}&undelivered_orders=${unDeliveredOrders}&insertion_type=${insertionType}`
    );
    if (!isEmpty(searchKeyType) && !isEmpty(searchKeyValue)) {
      url = `${url}&${searchKeyType}=${encodeURIComponent(searchKeyValue)}&search_type=${search_type? search_type : 'EQUALS'}&search_key=${searchKeyType}&search_value=${encodeURIComponent(searchKeyValue)}`;
    }
    if (!isEmpty(exception_code)&& status==="EXCEPTION") {
      url = `${url}&exception_code=${exception_code}`;
    }
    // if (saveNSearch) {
    //   url = `${url}&save_search_history=${saveNSearch}`;
    // }
  }

  url = `${url}&operation_code=COI`;
  url = `${url}&stats_only=${stats_only}`;
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { orders: "", pagination: "meta.pagination"  , stats : "stats"},
  });
}

export function fetchDeletedOrders({
  fromDate = '',
  toDate = '',
  page = '',
  perPage = '',
  searchText = !_.isEmpty(searchText) ? searchText : "",
}) {
  const url = ApiUrl(`v2/customer_orders/get_deleted_orders?page=${page}&per_page=${perPage}&search_value=${searchText}&from_date=${fromDate}&to_date=${toDate}`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { orders: "", pagination: "meta.pagination", stats: "stats" },
  });
}

export function restoreOrders(payload){
  const url = ApiUrl(`v2/customer_orders/restore_deleted_orders`);
  return Call("put", url, payload, ParseGeneralResponse, {
    responseDataKeys: { data: '' }
  });
}

export function refreshOrders(payload){
  const url = ApiUrl(`v2/organization/reset_org_data`);
  return Call("put", url, payload, ParseGeneralResponse, {
    responseDataKeys: { data: '' }
  });
}

export function fetchRouteOrders (navRouteId) {
  const url = ApiUrl(
    `v2/nav_routes/orders_in_route?nav_route_id=${navRouteId}&operation_code=NROR`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { orders: "" },
  });
}

export function printOrderDetails (customer_order_id) {
  const url = ApiUrl(
    `v2/customer_orders/print_order_details?customer_order_id=${customer_order_id}`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { orders: "" },
  });
}

export function fetchRateConfirmation(order_id) {
  const url = ApiUrl(`v2/tenders/confirm_tender?order_id=${order_id}`);
  return Call("PUT", url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: "" },
  });
} 

export function updateDispatchColorStatus(data) {
  const url = ApiUrl(`/v2/customer_orders/update_order_color_tag`);
  return Call("PUT", url, data, ParseGeneralResponse, {
    responseDataKeys: { data: "" },
  });
}



export function fetchOrderDetails(order, visibleTo = "ALL") {
  const url = ApiUrl(`v2/customer_orders/${order}?visible_to=${visibleTo}&operation_code=COS`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { orders: "customer_orders" },
  });
}


export function deleteOrder (id) {
  const url = ApiUrl(`v2/customer_orders/${id}`);
  return Call("delete", url, { operation_code: 'COD' }, ParseGeneralResponse);
}

export function deleteOrderPic(data) {
  data.operation_code='NRDSOP';
  const url = ApiUrl("v2/nav_routes/delete_stop_order_pictures");
  return Call("delete", url, data, ParseGeneralResponse);
}

export function changeOrderStatus (id) {
  const url = ApiUrl("v2/customer_orders/move_exception_order_to_pending");
  return Call("put", url, { customer_order_id: id, update_orders_status: 'COSU' }, ParseGeneralResponse);
}

export function getOrderDetailsForFeedback (data) {
  const url = GenerateURL(`feedback/details?order_id=${data}`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { feedback: "" },
  });
}

export function saveFeedback (data) {
  const url = ApiUrl("v2/feedbacks");
  return Call("POST", url, data, ParseGeneralResponse, {
    responseDataKeys: { feedback: "" },
  });
}

export function updateItem (data, isNew) {
  const url = ApiUrl("v2/customer_orders/update_customer_order_item");
  data.operation_code = isNew ? "CCOI" : "UCOI";
  const method = isNew ? "POST" : "PUT";
  return Call(method, url, data, ParseGeneralResponse, {
    responseDataKeys: {
      item: "order_item",
      order_area: "order_area",
      order_weight: "order_weight",
      order_weight_in_kgs: "order_weight_in_kgs",
      order_quantity: "order_quantity",
      order_pallets: "order_pallets",
    },
  });
}

export function createItem(data, isNew) {
  data.operation_code = isNew ? "CCOI" : "UCOI";
  const url = ApiUrl("v2/customer_orders/create_customer_order_item");
  const method = isNew ? "POST" : "PUT";
  return Call(method, url, data, ParseGeneralResponse, {
    responseDataKeys: {
      order_items: "order_items",
      order_area: "order_area",
      order_weight: "order_weight",
      order_weight_in_kgs: "order_weight_in_kgs",
      order_quantity: "order_quantity",
      order_pallets: "order_pallets",
    },
  });
}

export function fetchOriginDestination() {
  const url = ApiUrl(`/v2/customer_orders/airport_codes`);
  return Call(
    'get', url, {}, ParseGeneralResponse,
    { responseDataKeys: { airports: "airports" } },
  );
}

export function deleteItem (id) {
  const url = ApiUrl("v2/customer_orders/delete_customer_order_item");
  return Call(
    "delete",
    url,
    { item_id: id, operation_code: "DCOI" },
    ParseGeneralResponse,
    {
      responseDataKeys: {
        message: "message",
        order_area: "order_area",
        order_weight: "order_weight",
        order_quantity: "order_quantity",
        order_pallets: "order_pallets",
      },
    }
  );
}

export function groupItems(data) {
  data.operation_code = "GCOI";
  const url = ApiUrl("v2/customer_orders/add_stamp_order_items_group_id");
  const method =  "POST"
  return Call(method, url, data, ParseGeneralResponse, {
    responseDataKeys: {
      order_items: "order_items",
    },
  });
}

export function changeStatusOfOrders(data) {
  data.operation_code = 'COSU';
  const formData = setObjectToFormData(data);
  const url = ApiUrl("v2/customer_orders/update_orders_status");
  return Call(
    "PUT",
    url,
    formData,
    ParseGeneralResponse,
    {
      responseDataKeys: { orders: "" },
    },
    false
  );
}

export function sendFormEmail(emails,remarks,customerOrderId,locationId){
  const url = ApiUrl(`v2/form_responses/send_form_response?emails=${emails}&description=${remarks}&order_id=${customerOrderId}&location_id=${locationId}`);
  return Call("post", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      success: "success",
      message: "message",
    },
  });
}

export function changeReceivedToVerify(data) {
  data.operation_code = 'CAMV';
  const formData = setObjectToFormData(data);
  const url = ApiUrl("v2/customer_orders/confirm_appointments");
  return Call(
    "PUT",
    url,
    formData,
    ParseGeneralResponse,
    {
      responseDataKeys: { orders: "" },
    },
    false
  );
}

export function changeStatusToException(data) {
  data.operation_code='MOE';
  const formData = data // setObjectToFormData(data);
  const url = ApiUrl("v2/customer_orders/move_order_to_exception");
  return Call(
    "PUT",
    url,
    formData,
    ParseGeneralResponse,
    {
      responseDataKeys: { orders: "" },
    },
    false
  );
}

export function fetchOrdersForPreplan (
  status = "VERIFIED",
  preplanId = "",
  warehouse = "",
  data = {}
) {
  let url = ApiUrl(
    `v2/pre_plan/pre_plan_filter1?pre_plan_id=${preplanId}`
    // `v2/pre_plan/pre_plan_filter?status=${status}&pre_plan_id=${preplanId}&warehouse_id=${warehouse}`o
  );
  // if (data.sortBy) {
  //   url += `&sort_by=${data.sortBy}`;
  // }
  // if (data.deliveryStartDate) {
  //   url += `&delivery_start_date=${data.deliveryStartDate}`;
  // }
  // if (data.deliveryEndDate) {
  //   url += `&delivery_end_date=${data.deliveryEndDate}`;
  // }
  // if (!isEmpty(data.accountCodes)) {
  //   url += `&account_codes=${data.accountCodes}`;
  // }
  // if (data.groupBy) {
  //   url += `&group_by=${data.groupBy}`;
  // }
  // if (data.zone_ids && data.zone_ids.length > 0) {
  //   url += `&zone_ids=${data.zone_ids.join(",")}`;
  // }
  if (data.customer_order_ids && data.customer_order_ids.length > 0){
    url += `&customer_order_ids=${data.customer_order_ids.join(",")}`;
  }
  //   url += `&operation_code=PPF`;
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { orders: "pre_plan" },
  });
}

export function deleteMultipleOrder (id) {
  const url = ApiUrl(`v2/customer_orders/${id}`);
  return Call("delete", url, { id }, ParseGeneralResponse);
}

export function scheduleAppointments(data) {
  data.operation_code = 'SBA';
  const formData = setObjectToFormData(data);
  const url = ApiUrl("v2/customer_orders/schedule_bulk_appointments");
  return Call(
    "PUT",
    url,
    formData,
    ParseGeneralResponse,
    {
      responseDataKeys: { orders: "" },
    },
    true
  );
}

export function exportReport (
  orderNumber,
  status,
  formattedFromDate,
  formattedToDate,
  selectedOrderKeys,
  sortBy
) {
  let url = "";

  if (selectedOrderKeys.length > 0) {
    url = `${AppConfig.baseUrl}/api/v2/customer_orders/download_csv?customer_order_ids=${selectedOrderKeys}`;
  } else if (orderNumber !== "") {
    url = `${AppConfig.baseUrl}/api/v2/customer_orders/download_csv?customer_order_number=${orderNumber}`;
  } else {
    url = `${AppConfig.baseUrl}/api/v2/customer_orders/download_csv?status=${status}&from_date=${formattedFromDate}&to_date=${formattedToDate}&sort_by=${sortBy}`;
  }
  url = `${url}&operation_code=DOCSV`;
  axios({
    url,
    method: "GET",
    responseType: "blob", // important
    data,
    // headers: { 'Accept': 'application/vnd.ms-excel' }
  })
    .then((response) => {
      const httpurl = window.URL.createObjectURL(new Blob([ response.data ]));
      const link = document.createElement("a");
      link.href = httpurl;
      link.setAttribute("download", "file.xls");
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      alertMessage(I18n.t("messages.not_found"), "error");
    });
}

export function saveOrderAccessorials (data) {
  data.operation_code = 'AAAOA';
  const url = ApiUrl("v2/account_accessorials/add_organizaiton_accessorial");
  return Call("POST", url, data, ParseGeneralResponse, {
    responseDataKeys: { data: "" },
  });
}
export function fetchOrderAccessorials (order, type='', loc = '') {
  const location_id = loc ? `&location_id=${loc}` : '';
  const url = ApiUrl(
    `v2/customer_orders/order_associate_accessorials?customer_order_id=${order}&operation_code=OAA&order_type=${type}${location_id}`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { accessorials: "order_accessorial" },
  });
}

export function fetchAvailableDates (accountCode, zipCode) {
  const url = GenerateURL(`tracking/delivery_dates_based_on_zip?account_code=${accountCode}&zip_code=${zipCode}&operation_code=DDBOZ`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { availableDates: "" },
  });
}

export function fetchOrderEstimationAmount (order) {
  const url = ApiUrl(
    `v2/customer_orders/order_estimation_amount?customer_order_id=${order}&operation_code=OEA`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { estimation_amount: "estimation_amount" },
  });
}
export function sendPodImagesToEmails (data) {
  data.operation_code = 'SPOD';
  const url = ApiUrl(`v2/customer_orders/${data.customer_order_id}/send_pods`);
  return Call("POST", url, data, ParseGeneralResponse, {
    responseDataKeys: { data: "" },
  });
}

export function fetchBillOfRating (orderId) {
  const url = ApiUrl(
    `v2/billing/order_bill_of_rate?customer_order_id=${orderId}&operation_code=OBLR`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { bill_of_rate: "bill_of_rate", success: "success" },
  });
}

export function createOrderQuoteAmount (data) {
  data.operation_code = 'COQA';
  const url = ApiUrl("v2/billing/create_order_quote_amount");
  const method = "POST";
  return Call(method, url, data, ParseGeneralResponse, {
    responseDataKeys: { message: "message" },
});
}
export function fetchOrderQuoteAmount (customerOrderIds, organizationId) {
  const url = ApiUrl(
    `v2/billing/order_quote_amount?customer_order_ids=${customerOrderIds}&organization_id=${organizationId}&operation_code=OQA`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { quotes: "customer_order_quotations", success: "success" },
  });
}

export function fetchQuoteOrderAmount (customerOrderIds) {
  const url = ApiUrl(
    `v2/quote_orders/quote_order_amount?customer_order_id=${customerOrderIds}`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {quote_orders: "quote_orders",  success: "success" },
  });
}

export function fetchOrderReferences(organization_id, account_id) {
  const url = ApiUrl(
    `v2/order_references?organization_id=${organization_id}&account_id=${account_id}`
  );

  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: {
        references: "references",
      },
    },
    false
  );
}
// dispatcher notes
export const dispatcherNotesApi = {
  create (data) {
    const url = ApiUrl("v2/customer_order_notes");
    return Call("POST", url, data, ParseGeneralResponse, {
      responseDataKeys: { dispatch_notes: "dispatch_notes" }
    });
  },
  update (data) {
    const { customer_order_notes_id, notes, organization_id} = data
    const url = ApiUrl(`v2/customer_order_notes/${customer_order_notes_id}`);
    return Call("PUT", url, { notes, organization_id }, ParseGeneralResponse, {
      responseDataKeys: { dispatch_notes: "dispatch_notes" }
    });
  },
  delete(customer_order_id){
    const url = ApiUrl(`v2/customer_order_notes/${customer_order_id}`);
    return Call("DELETE", url, {}, ParseGeneralResponse, {
      responseDataKeys: { dispatch_notes: "dispatch_notes" }
    });
  },
  fetch(customer_order_id){
    const url = ApiUrl(`v2/customer_order_notes/order_notes?order_id=${customer_order_id}`);
    return Call("GET", url, {}, ParseGeneralResponse, {
      responseDataKeys: { dispatch_notes: "customer_order_notes" }
    });
  }

}

export const privateNotesApi = {
  create (data) {
    const url = ApiUrl("v2/customer_order_private_notes");
    return Call("POST", url, data, ParseGeneralResponse, {
      responseDataKeys: { dispatch_notes: "private_notes" }
    });
  },
  update (data) {
    const { customer_order_notes_id, notes, private_notes, organization_id} = data
    const url = ApiUrl(`v2/customer_order_private_notes/${customer_order_notes_id}`);
    return Call("PUT", url, { notes, organization_id,private_notes }, ParseGeneralResponse, {
      responseDataKeys: { dispatch_notes: "private_notes" }
    });
  },
  delete(customer_order_id){
    const url = ApiUrl(`v2/customer_order_private_notes/${customer_order_id}`);
    return Call("DELETE", url, {}, ParseGeneralResponse, {
      responseDataKeys: { dispatch_notes: "private_notes" }
    });
  },
  fetch(customer_order_id){
    const url = ApiUrl(`v2/customer_order_private_notes?order_id=${customer_order_id}`);
    return Call("GET", url, {}, ParseGeneralResponse, {
      responseDataKeys: { dispatch_notes: "private_notes" }
    });
  }

}

export const resolveAddressApi = {
  clearFalseNegative (org_id, wrongAddressOrderIds) {
    const url = ApiUrl("v2/loc_update/clear_location_error_flag");
    const data = {
      org_id,
    };
    if (_.isArray(wrongAddressOrderIds)) {
      data.location_ids = [ ...wrongAddressOrderIds ];
    } else {
      data.location_ids = [wrongAddressOrderIds];
    }
    delete data.order_ids
    return Call("PUT", url, data, ParseGeneralResponse, {
      responseDataKeys: { data: "" }
    });
  },
  fixWrongAddress (data) {
    data.location_ids = data.order_ids
    delete data.order_ids
    const url = ApiUrl("v2/loc_update/update_order_address");
    return Call("PUT", url, data, ParseGeneralResponse, {
      responseDataKeys: { label_file: "label_file" }
    });
  }
}

export function fetchUpdatedSignerDetails(payload) {
  const url = ApiUrl("v2/stop_order_pictures/update_signer_details");
  return Call("PUT", url, payload, ParseGeneralResponse, {
    responseDataKeys: { message: "message" },
  });
}

// export const dynamicFileDownload = {
//   getSampleOrderFile (fileType, org_id, account_code) {
//     const url = ApiUrl("v2/csv_column_mappings/order_sample_file_download");
//     const data = {
//       org_id: org_id,
//       account_code: account_code || "",
//       type_of_file : fileType
//     };
//     // download file and get file name from Content-Disposition
//     let filename = `sample_file.${fileType === 'CSV' ? 'csv' : 'xlsx'}`;
//     return new Promise((resolve, reject) => {
//     Axios({
//       method,
//       url,
//       headers: { "Content-Type": "application/json" },
//       data,
//       // responseType: "blob"
//     }).then(
//       // fetch(url, { method: "POST", body: JSON.stringify(data), headers: { "Content-Type": "application/json" } }).then(
//         res => {
//           if(res.status === 200){
//           if (res.headers.get("content-disposition")) {
//             filename = res.headers.get("content-disposition").split("filename=")[1].replace(/"/g, "");
//           }
//           blobDownload(res, filename , resolve);
//         }
//         else{
//           res.json().then(errors=> {
//             reject(errors.errors);
//           }
//           );
//         }})
//       }
//     );
//   }
// }
export function moveOrdersToNewFromPending(data) {
  data.operation_code = 'COUPO';
  const formData = setObjectToFormData(data);
  const url = ApiUrl("v2/customer_orders/update_pending_orders");
  return Call(
    "PUT",
    url,
    formData,
    ParseGeneralResponse,
    {
      responseDataKeys: { orders: "" },
    },
    false
  );
}
export function downloadLabels (payload) {
  payload.operation_code = "COLP"
  let url = ApiUrl(`v2/export_customer_orders/download_order_labels?order_ids=${payload.customer_order_ids}`);
  if(!isEmpty(payload.label_type)) {
    url = `${url}&label_type=${payload.label_type}`;
  }
  return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: { label_file: "label_file" }
    }
  );
}

export function cloneOrders(data) {
  data.operation_code = 'COCL';
  const formData = setObjectToFormData(data);
  const url = ApiUrl("v2/order_cloning/clone_customer_order");
  return Call(
    "POST",
    url,
    formData,
    ParseGeneralResponse,
    {
      responseDataKeys: { orders: "order_details" },
    },
    true
  );
}

export default {
  fetchLocationOrders,
  fetchTaskOrders,
  dispatcherNotesApi,
  resolveAddressApi
};

export function uploadOrdersHistory(organization_id,page = 1, perPage = AppConfig.perPage,) {
  const url = ApiUrl(`v2/import_orders/import_orders_history?${PageParams(page, perPage)}&operation_code=UOHG`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { orders_import_logs: "import_orders", message: "message",  pagination: "meta.pagination",},
  });
}

export function updateLocationData({customer_order_number, organization_id, latitude, longitude}) {
    const url = ApiUrl('/v2/locations/order_lat_long_updation');
    const method = 'PUT';
    // Create a new FormData object
    const formData = new FormData();
    formData.append('customer_order_number', customer_order_number);
    formData.append('organization_id', organization_id);
    formData.append('latitude', latitude);
    formData.append('longitude', longitude);
    return Call(method, url, formData, ParseGeneralResponse, {
      responseDataKeys: { location: '' },
    });
}

export function updateOrderStatusData(data) {
  const url = ApiUrl(`v2/customer_orders/update_location_status`);
  return Call("PUT", url, data, ParseGeneralResponse, {
    responseDataKeys: { message: "message" },
  });
}

export function fetchOrderItems (order) {
  const url = ApiUrl(
    `v2/customer_orders/${order}/order_items`   //?operation_code=LCOI
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { items: "order_items" },
  });
}

export function fetchOrderPictures(order) {
  const url = ApiUrl(
    `v2/pre_plan/order_attachments?order_id=${order}`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { images: "" },
  });
}

export function downloadOrderPictures(refer_id,location_id,picture_ids) {
  const url = ApiUrl(
    `v2/stop_order_pictures/download_attachments?refer_id=${refer_id}&location_id=${location_id}&pictures_ids=${picture_ids}`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: "" },
  });
}

export function PdfPreviewPictures(data) {
  const formData = setObjectToFormData(data);
  const url = ApiUrl("v2/customer_orders/upload_documents");
  return Call(
    "POST",
    url,
    formData,
    ParseGeneralResponse,
    {
      responseDataKeys: { orders: "" },
    },
    false
  );
}

export function downloadBarCodes (data) {
  const url = ApiUrl(`v2/bar_code_details/download_bar_codes`);
  return Call("POST", url, data, ParseGeneralResponse, {
    responseDataKeys: { label_file: "label_file" },
  });
}

export function EditArrivalAndDepartureTime(payload) {
  const url = ApiUrl(`v2/customer_orders/update_actual_start_end_times/`);
  return Call("PUT", url, payload, ParseGeneralResponse, {
    responseDataKeys: { message: "message" },
  });
}

export function fetchOrgPredefinedStops ({search_text}) {
  const url = ApiUrl(`v2/predefined_stops/search?search_text=${search_text}&limit=5`);
  return Call("GET", url, {}, ParseGeneralResponse, {
    responseDataKeys: { predefined_stops: "predefined_stops" },
  });
}

export function getSearchValueSubOrders(search_value, organization_id,whId) {
  const url = ApiUrl(`v2/customer_orders/fetch_orders?search_value=${search_value}&organization_id=${organization_id}&warehouse_ids=${whId}`);
  return Call("GET", url, {}, ParseGeneralResponse, {
    responseDataKeys: { orders: "orders" },
  });
}

export function saveSubOrders(payload){
  const url = ApiUrl(`v2/line_haul_orders/add_orders_to_lh_order`);
  return Call("PUT", url, payload, ParseGeneralResponse, {
    responseDataKeys: { data: "" },
  });
}

export function deleteSubOrders(payload){
  const url = ApiUrl(`v2/line_haul_orders/delete_orders_to_lh_order`);
  return Call("DELETE", url, payload, ParseGeneralResponse, {
    responseDataKeys: { data: "" },
  });
}

export function clearSearchCriteria(){
  const url = ApiUrl(`v2/customer_orders/clear_search_criteria`);
  return Call("DELETE", url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: "" },
  });
}

export function verifyBillingZone (orgId,accountId,zipcode,city) {
 
  let url = ApiUrl(`v2/billing_zones/to_get_zipcode_billing_warnings?organization_id=${orgId}&zipcode=${zipcode}&city=${city}`);
  if(accountId){
    url = `${url}&account_id=${accountId}`
  }
  return Call("GET", url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: "" },
  });
}

// export function fetchWms(order_id) {
//   const url = ApiUrl(`/v2/warehouse_items/get_wh_tracks_and_comments?refer_id=${order_id}`);
//   return Call(
//     'get', url, {}, ParseGeneralResponse,
//     { responseDataKeys: { unload_details: "unload_details", wh_comments : "wh_comments",wh_status : "wh_status",received_at : "received_at",pictures:"pictures" } },
//   );
// }

export function fetchWms (payload) {
  const {
    referId = "",
    currentRole = "",
    orgId = "",
    warehouseIds = "",
    java_token = ""
  } = payload;
  const url = javaWmsUrl(`/api/order/getWhCommentsAndTracks?referId=${referId}&orgId=${orgId}&warehouseIds=${warehouseIds}&currentRole=${currentRole}&java_token=${java_token}`);
  return Call("get", url, {}, ParseGeneralResponse, { responseDataKeys: "" });
}


export function wmsPreCall (payload) {
  const url = javaWmsUrl(`/api/auth/user_token`);
  return Call("POST", url, payload, ParseGeneralResponse, { responseDataKeys: { token: "token" } });
}


export function updateSubOrders(payload){
  const url = ApiUrl(`v2/line_haul/update_sub_orders`);
  return Call("PUT", url, payload, ParseGeneralResponse, {
    responseDataKeys: { data: "sub_orders" },
  });
}

export function createOrderFromFile(data) {
  data.operation_code = 'SCA';
  const formData = setObjectToFormData(data);
  const url = ApiUrl("v2/customer_orders/extract_pdf");
  const method = 'POST'
  return Call(
    method,
    url,
    formData,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}

export function createOrderFromPdfId(data) {
  data.operation_code = 'SCA';
  const formData = setObjectToFormData(data);
  const url = ApiUrl("v2/customer_orders/extract_pdf");
  const method = 'POST'
  return Call(
    method,
    url,
    formData,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}

export function changeDriverOnCompletedOrder(data) {
  const url = ApiUrl("v2/drivers/update_existing_driver");
  return Call(
    "PUT",
    url,
    data,
    ParseGeneralResponse,
    {
      responseDataKeys: { data: "" },
    },
  );
}

export function tenderDriverCreate(data) {
  const url = ApiUrl("v2/tenders/create_tender");
  return Call(
    "POST",
    url,
    data,
    ParseGeneralResponse,
    {
      responseDataKeys: { data: "" },
    },
  );
}

export function exportOrders(url) {
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { message: "message" },
  });
}

export function fetchOrderStatuses(order_id) {
  const url = ApiUrl(
    `v2/order_status_codes/get_order_status_codes?customer_order_id=${order_id}`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { order_statuses: "" },
  })
}

export function sendOrderStatuses(data) {
  const url = ApiUrl("v2/order_status_codes/send_order_statuses");
  const method =  "POST"
  return Call(method, url, data, ParseGeneralResponse, {
    responseDataKeys: {
      message: "message",
    },
  });
}

export function sendEmailsForEstimateBilling(payload) {
  const {
    organization_id,
    customer_order_ids,
    email_ids,
    subject,
    description,
  } = payload;
  const url = ApiUrl(
    `v2/billing/estimate_billing_pdf?organization_id=${organization_id}&customer_order_ids=${customer_order_ids}&email_ids=${email_ids}&subject=${subject}&description=${description}`
  );
  return Call("GET", url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: "" },
  });
}

export function mawbConnections(data) {
  const url = ApiUrl('/v2/customer_orders/update_order_mawb');
  return Call("PUT", url, data, ParseGeneralResponse, {
    responseDataKeys: { data: "" },
  });
}

export function fetchConsolidatedMawbs(payload) {
  const url = ApiUrl(`v2/customer_orders/get_same_mawb_order_details?order_id=${payload.order_id}`);
  return Call("GET", url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: "" },
  });
}

export const newDispatchMultiStopRoute = (data) => {
  const url = ApiUrl("v2/multi_stop_orders/create_nav_route");
  return Call("post", url, data, ParseGeneralResponse, {
    responseDataKeys: { nav_route: "nav_route", response: '' },
  });
};

export function downloadConsolidatedBols(orderIds, orgId) {
  const url = ApiUrl(`v2/billing/get_consolidated_bols_pdf?customer_order_ids=${orderIds.join(',')}&org_id=${orgId}`);
  return Call("GET", url, {}, ParseGeneralResponse, {
    responseDataKeys: { bol_file: "bol_file" },
  });
}

export function getBulkOrderComplete(orderIds) {
  let url = ApiUrl(`v2/customer_orders/get_orders_details?order_ids=${orderIds}`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: 'order_details' },
  });
}
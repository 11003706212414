/* eslint-disable no-nested-ternary */
import React, { Fragment, Component } from 'react'
import _ from 'lodash'
import {isEmpty, alertMessage } from '../../common/Common'
import I18n from '../../common/I18n'
import {
  Icon,
  Row,
  Col,
  Input,
  Radio,
  Select,
  Spin,
  Modal,
  Tooltip,
} from '../../common/UIComponents'
import userStore from '../../stores/UserStore'
import FormErrors from '../common/FormErrors'
import FormButtons from '../common/FormButtons'
import {
  fetchOrderAccessorials,
  saveOrderAccessorials,
} from '../../api/OrdersApi'
import {
  fetchAccessorialDetails,
  fetchAccessorialsComponents,
  fetchAccountAccessorials as fetchAccountComponentAccessorials,
} from '../../api/Accessorials'
import { setAccessorialResponse } from '../../helpers/orders'
import OrderAccessorialRowList from './OrderAccessorialRowList'
import { fetchTechnicians } from '../../api/UsersApi'
import AppConfig from '../../config/AppConfig'
import { getSlotInfo } from '../configurations/helper'
import { renderAlertMessage } from '../../helpers/common'
import masterRateSheet from "../../../assets/images/masterLevelAccessorial.png";
import accLevel from "../../../assets/images/accLevelAccessorial.png";

const { confirm } = Modal
class OrderAccAccessorial extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accountComponentAccessorial: [],
      orgComponents: [],
      orderAccessorials: [],
      accountId: props.account_id,
      orgId: props.org_id,
      orderId: props.customer_order_id,
      displayComponents: [],
      currentLocation: props.currentLocation,
      drivers: props.drivers || [],
      technicians: [],
      slotConfig: {},
      authorizeDetails: {},
      decimalPoints: window.localStorage.getItem('round_off_decimals'),
      updatedKey: this.props.updatedKey,
      losCode: props.losCode || '',
    }
  }


  componentDidMount() {
    this.getOrgComponents()
    this.getUsers()
    // this.getOrderAccessorials();
    // this.getAccountComponentAccessorials();
  }

  getUsers = () => {
    this.setState({ inProgress: true })
    fetchTechnicians().then((result) => {
      if (result.success) {
        const technicians = result.users || []
        this.setState(
          {
            technicians,
          }
        )
      } else {
        this.setState({ inProgress: false, technicians: [] })
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.account_id !== this.state.accountId || 
       (nextProps.updatedKey !== this.state.updatedKey )) //&& nextProps.losCode !==  this.state.losCode 
    {
      if(nextProps.account_id !== this.state.accountId){
        this.setState(
          {
            accountId: nextProps.account_id,
          },
          () => {
            this.getAccountComponentAccessorials()
          },
        )
      } else {
        this.getAccountComponentAccessorials()
      }
    }
    if (nextProps.drivers !== this.state.drivers) {
      this.setState(
        {
          drivers: nextProps.drivers || [],
        }
        )
    }
  }

  getDeifinitions = (accessorial) => {
    const { orgComponents, displayComponents } = this.state
    if (!isEmpty(accessorial)) {
      const components = accessorial.components || []
      const accComponents = components.map(
        (component) => component.component_code,
      )
      const componentDefs = orgComponents.filter(
        (component) =>
          accComponents.includes(component.code) &&
          displayComponents.includes(component.code),
      )
      return componentDefs
    }
    return []
  }

  getSlotDetails = (id, reqCode = '') => {
    this.getAccessorialDetails(id, reqCode)
  }

  getAccessorialDetails = (id, reqCode) => {
    fetchAccessorialDetails(id).then((result) => {
      if (result.success) {
        const slotConfig = {...this.state.slotConfig};
        const currentAccessorial = result.accessorial || [];
        const componentParams = currentAccessorial ? currentAccessorial.component_params : [];
        const slotParams = !isEmpty(reqCode) ? 
        componentParams.filter(param => param.component_code === reqCode) :
        [...componentParams];
        slotConfig[id] = slotParams
        this.setState(
          {
            inProgress: false,
            slotConfig
          }
        );
      } else {
        const slotConfig = {...this.state.slotConfig};
        slotConfig[id] = []
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false, slotConfig });
      }
    });
  };

  getAccountComponentAccessorials = () => {
    if (this.state.accountId) {
      this.setState({ inProgress: true })
      const { orgId, accountId, orgComponents, displayComponents } = this.state
      fetchAccountComponentAccessorials(orgId, accountId, 'DISPATCHER').then(
        (result) => {
          if (result.success) {
            const accessorials = result.accessorials || []
            const accountComponentAccessorial = accessorials.map(
              (accessorial) => {
                const componentDefs = this.getDeifinitions(accessorial)
                const slotObject = _.find(componentDefs, {code : 'SLOTS'});
                if(!isEmpty(slotObject)){
                  this.getSlotDetails(accessorial.id, 'SLOTS');
                } 
                return Object.assign({}, accessorial, {
                  componentDefs,
                })
              },
            )
            this.setState(
              {
                inProgress: false,
                // accountComponentAccessorial: _.sortBy(accountComponentAccessorial, ["standard_code", "accessorial_code"]),
                accountComponentAccessorial: _.sortBy(accountComponentAccessorial, (item) => item.accessorial_name.toLowerCase()),

              },
              () => {
                this.getOrderAccessorials()
              },
            )
          }else {
            this.setState({ inProgress: false })
          }
        },
      )
    }
  }

  getOrderAccessorials = () => {
    const { orderId, currentLocation } = this.state;
    fetchOrderAccessorials(orderId, '', currentLocation).then((result) => {
      if (result.success) {
        const orderAccessorial = result.accessorials || {}
        const accessorials = orderAccessorial.account_accessorial_params || [];
        const authorizeDetails = {}
        const allowedIds = {};
        this.state.accountComponentAccessorial.forEach(
          (accessorial) => {
            const components = accessorial.components ? 
            accessorial.components.map(record => record.component_code)
            :
            [];
            allowedIds[accessorial.id] = [...components];
        })
        const orderAccessorials = setAccessorialResponse(
          accessorials,
          true,
          allowedIds,
        )
        orderAccessorials.forEach(rec => {
          if(!isEmpty(rec.authorized_by)){
            authorizeDetails[rec.accessorial_code] = {
              authorized_by: rec.authorized_by,
              authorization_code: rec.authorization_code,
              comments: rec.comments,
              is_modified: false,
            }
          }
        })
        this.setState(
          {
            orderAccessorials,
            authorizeDetails,
          },
          // () => {
          //   if (orderAccessorials.length === 0) {
          //     this.state.accountComponentAccessorial
          //       .filter(
          //         (accss) =>
          //           !isEmpty(accss.standard_code) && accss.is_clone === "false"
          //       )
          //       .forEach((accessorial) => {
          //         this.handleCheckStandardAccessorial(
          //           accessorial.id,
          //           accessorial.accessorial_code,
          //           true,
          //           !isEmpty(accessorial.standard_code),
          //         );
          //       });
          //   }
          // }
        )
      } else {
        renderAlertMessage(result.errors)
      }
    })
  }

  getOrgComponents = () => {
    fetchAccessorialsComponents().then((result) => {
      if (result.success) {
        const orgComponents = result.components || []
        const displayComponents = []
        const noParamComponents = []
        orgComponents.forEach((component) => {
          if (
            component.component_params &&
            component.component_params.length > 0
          ) {
            const componentParams = component.component_params || []
            const visibleParams = componentParams.filter((param) =>
              param.visible_to.includes('DISPATCHER'),
            )
            if (visibleParams.length > 0) {
              displayComponents.push(component.code)
            } else {
              noParamComponents.push(component.code)
            }
          } else {
            noParamComponents.push(component.code)
          }
        })
        this.setState(
          {
            orgComponents,
            displayComponents,
            noParamComponents,
          },
          () => {
            this.getAccountComponentAccessorials()
          },
        )
      }
    })
  }
  _renderCollectionComp = () => <div />

  updatePropValues = () => {
    const { handleOnChange } = this.props;
    if(handleOnChange && typeof handleOnChange === "function"){
      handleOnChange('isModified', true);
    }
  }

  handleOnParamChange = (
    accParam,
    componentCode,
    accountAccessorialId,
    eleValue,
  ) => {
    const { orderAccessorials } = this.state
    const accountAccessorialParamIndex = _.findIndex(orderAccessorials, [
      'account_accessorial_id',
      accountAccessorialId,
    ])
    if (accountAccessorialParamIndex >= 0) {
      const accountAccessorialParam = _.cloneDeep(
        orderAccessorials[accountAccessorialParamIndex],
      )
      const componentParams = accountAccessorialParam.component_params
        ? accountAccessorialParam.component_params
        : []
      const componentIndex = _.findIndex(componentParams, [
        'component_code',
        componentCode,
      ])
      let currentComponent = {}
      if (componentIndex >= 0) {
        currentComponent = componentParams[componentIndex]
        if (isEmpty(currentComponent.component_values)) {
          currentComponent.component_values = []
        }
        const paramObjIndex = _.findIndex(currentComponent.component_values, [
          'accessorial_key',
          accParam,
        ])
        if (paramObjIndex >= 0) {
          currentComponent.component_values[
            paramObjIndex
          ].accessorial_value = eleValue
        } else {
          currentComponent.component_values.push({
            accessorial_key: accParam,
            accessorial_value: eleValue,
          })
        }
        componentParams[componentIndex] = { ...currentComponent }
      } else {
        currentComponent = {
          component_code: componentCode,
          component_values: [],
        }
        currentComponent.component_values.push({
          accessorial_key: accParam,
          accessorial_value: eleValue,
        })
        componentParams.push({ ...currentComponent })
      }
      accountAccessorialParam.component_params = [...componentParams]
      orderAccessorials[accountAccessorialParamIndex] = {
        ...accountAccessorialParam,
      }
      
      this.setState({
        orderAccessorials,
      })
      // this.props.handleStateOnchange('orderAccessorials', orderAccessorials);
    }
  }

   handleOnChange = (
    accountAccessorialId,
    element,
    value,
    source = ""
  ) => {
    const { orderAccessorials } = this.state
    const accountAccessorialParamIndex = _.findIndex(orderAccessorials, [
      'account_accessorial_id',
      accountAccessorialId,
    ])
    if (accountAccessorialParamIndex >= 0) {
      const accessorialObj = {...orderAccessorials[accountAccessorialParamIndex]};
      if(source === 'eligible_for'){
        accessorialObj.driver_pay_params = {
          ...accessorialObj.driver_pay_params,
          driver_id: value
        }
      } else if(element === 'attempted_driver_ids'){
        const attempted_driver_ids = accessorialObj.driver_pay_params?.attempted_driver_ids ? accessorialObj.driver_pay_params.attempted_driver_ids : [];
        if(source >= 0){
          if(isEmpty(value)){
            attempted_driver_ids.splice(source, 1)
          }else if(attempted_driver_ids.length >= source){
            attempted_driver_ids[source] = value;
          }
        } else {
          attempted_driver_ids.push(value);
        }
        accessorialObj.driver_pay_params = {
          ...accessorialObj.driver_pay_params,
          attempted_driver_ids,
        }
      } else if(element === 'time_slots'){
        const values = value.split('-')
        accessorialObj.driver_pay_params = {
          ...accessorialObj.driver_pay_params,
          time_slots: { from_time: values[0], to_time: values[1]}
        }
        
      } else {
        accessorialObj[element] = value;
      }
      orderAccessorials[accountAccessorialParamIndex] = accessorialObj;
      this.setState({
        orderAccessorials,
      })
      // this.props.handleStateOnchange('orderAccessorials', orderAccessorials);
    }
  }

  askConfirmMessage = (title, content, cb) => {
    confirm({
      title,
      content: <span className="">{content}</span>,
      onOk: () => {
        cb()
      },
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onCancel: () => {},
    })
  }

  handleCheckStandardAccessorial = (
    accountAccessorialId,
    accessorialCode,
    eleValue,
    isStandardCode,
  ) => {
    const { accountComponentAccessorial, orderAccessorials } = this.state
    const authorizeDetails = this.state.authorizeDetails ? _.cloneDeep(this.state.authorizeDetails): {};
    if(!isEmpty(authorizeDetails[accessorialCode])){
      authorizeDetails[accessorialCode] = { is_modified: false}
    }
    const accountAccessorial = _.find(accountComponentAccessorial, {
      id: accountAccessorialId,
    })
    if (eleValue) {
      const otherAccessorials = orderAccessorials
        .filter(
          (accessorial) =>
            accessorial.standard_code === accountAccessorial.standard_code,
        )
        .map((accessorial) => accessorial.accessorial_code)
      if (otherAccessorials.length > 0) {
        this.askConfirmMessage(
          I18n.t('messages.standard_change_confirm', {
            field: otherAccessorials.join(", "),
          }),
          I18n.t('messages.proceed_confirm'),
          () => {
            this.handleAddOrderCompStandardAccessorial(
              accountAccessorialId,
              accessorialCode,
              eleValue,
              isStandardCode,
            )
          },
        )
      } else {
        this.handleAddOrderCompStandardAccessorial(
          accountAccessorialId,
          accessorialCode,
          eleValue,
          isStandardCode,
        )
      }
    } else {
      const standardCode = accountAccessorial.standard_code
      const standardAccessorialIds = accountComponentAccessorial
        .filter((accessorial) => accessorial.standard_code === standardCode)
        .map((accessorial) => accessorial.id)

      // const standardAccessorialIds = standardAccessorial.map(
      //   (accessorial) => accessorial.id
      // );
      const otherAccessorials = orderAccessorials.filter(
        (accessorial) =>
          !standardAccessorialIds.includes(accessorial.account_accessorial_id),
      )
      this.setState({
        orderAccessorials: otherAccessorials,
        authorizeDetails,
      })
    }
  }

  handleAddOrderCompStandardAccessorial = (
    accountAccessorialId,
    accessorialCode,
    eleValue,
    isStandardCode,
  ) => {
    const { accountComponentAccessorial, orderAccessorials } = this.state
    const accountAccessorial = _.find(accountComponentAccessorial, {
      id: accountAccessorialId,
    })
    if (!isEmpty(accountAccessorial)) {
      const standardCode = accountAccessorial.standard_code
      const standardAccessorialIds = accountComponentAccessorial
        .filter(
          (accessorial) =>
            accessorial.id !== accountAccessorialId &&
            accessorial.standard_code === standardCode,
        )
        .map((accessorial) => accessorial.id)
      const otherAccessorials = orderAccessorials.filter(
        (accessorial) =>
          !standardAccessorialIds.includes(accessorial.account_accessorial_id),
      )
      this.setState(
        {
          orderAccessorials: otherAccessorials,
        },
        () => {
          this.handleAddOrderCompAccessorial(
            accountAccessorial.id,
            accountAccessorial.accessorial_code,
            true,
          )
        },
      )
    }
  }

  handleAddOrderCompAccessorial = (
    accountAccessorialId,
    accessorialCode,
    eleValue,
    isStandardCode,
  ) => {
    const { orderAccessorials } = this.state
    const authorizeDetails = this.state.authorizeDetails ? _.cloneDeep(this.state.authorizeDetails): {};
    if(!isEmpty(authorizeDetails[accessorialCode])){
      authorizeDetails[accessorialCode] = { is_modified: false}
    }
    if (eleValue) {
      const accountAccessorial = orderAccessorials.find(
        (acc) => acc.id === accountAccessorialId,
      )
      if (accountAccessorial) {
      } else {
        if(isEmpty(authorizeDetails[accessorialCode])){
          authorizeDetails[accessorialCode] = {};
        }
        authorizeDetails[accessorialCode]['is_modified'] = true;
        const { accountComponentAccessorial } = this.state;
        const accountAccessorialObj = _.find(accountComponentAccessorial, { id: accountAccessorialId });
        const accessorialObj = {};
        accessorialObj.account_accessorial_id = accountAccessorialId;
        accessorialObj.accessorial_code = accessorialCode;
        accessorialObj.standard_code = !isEmpty(accountAccessorialObj) ? accountAccessorialObj.standard_code : '';
        accessorialObj.component_params = [];
        accessorialObj.driver_pay_params= !isEmpty(accountAccessorial) && accountAccessorial.driver_pay_params ? accountAccessorial.driver_pay_params : null;
        // this.props.handleStateOnchange('orderAccessorials', [
        //   ...orderAccessorials,
        //   accessorialObj,
        // ]);
        this.setState({
          orderAccessorials: [...orderAccessorials, accessorialObj],
          authorizeDetails,
        })
      }
    } else {
      const newAccountAccessorials = orderAccessorials.filter(
        (acc) => acc.account_accessorial_id !== accountAccessorialId,
      )
      if(isEmpty(authorizeDetails[accessorialCode])){
        authorizeDetails[accessorialCode] = {};
      }
      authorizeDetails[accessorialCode]['is_modified'] = true;
      this.setState({
        orderAccessorials: [...newAccountAccessorials],
        authorizeDetails,
      })
      // this.props.handleStateOnchange('orderAccessorials', [...newAccountAccessorials]);
    }
    //this.updatePropValues();
  }

  handleSave = () => {
    const { accountComponentAccessorial } = this.state;
    const authorizeDetails = this.state.authorizeDetails ? _.cloneDeep(this.state.authorizeDetails): {};
    const orderAccessorials = _.cloneDeep(this.state.orderAccessorials);
    const config = this.props.config || {};
    const accountAccessorialParams = []
    // orderAccessorials.forEach((orderAccessorial) => {
    //   const object = {
    //     account_accessorial_id: orderAccessorial.account_accessorial_id,
    //     accessorial_code: orderAccessorial.accessorial_code,
    //     accessorial_type: orderAccessorial.accessorial_type,
    //     component_params: [],
    //   };
    //   if (orderAccessorial.component_params.length > 0) {
    //     orderAccessorial.component_params.map((param) => {
    //       accountAccessorialParams.push(
    //         Object.assign({}, object, { component_params: { ...param } })
    //       );
    //     });
    //   } else {
    //     accountAccessorialParams.push(object);
    //   }
    // });
    const errors = [];
    if(config.require_accessorial_authorization === 'true') {
      orderAccessorials.forEach((acc, index) => {
        if(authorizeDetails[acc.accessorial_code] && authorizeDetails[acc.accessorial_code].is_modified && isEmpty(authorizeDetails[acc.accessorial_code].authorized_by)){
          const reqObject = _.find(accountComponentAccessorial, {accessorial_code: acc.accessorial_code});
          errors.push(`${I18n.t('accessorials.authorize.by')} is required for ${reqObject ? reqObject.accessorial_name : acc.accessorial_code}`);
        } 
        orderAccessorials[index] = {
          ...orderAccessorials[index],
          ...authorizeDetails[acc.accessorial_code],
        }
      })
    }
    if(errors.length > 0){
      this.setState({
        errors,
      })
    } else {
      this.setState({ inProgress: true, errors: [] })
      const data = {
      organization_id: userStore.getStateValue('selectedOrg'),
      account_id: this.props.account_id,
      customer_order_id: this.props.customer_order_id,
      location_id: this.props.currentLocation,
      account_accessorial_params: [...orderAccessorials],
      }
      saveOrderAccessorials(data).then((result) => {
        if (result.success) {
          this.updatePropValues();
          this.setState({
            inProgress: false,
            errors: [],
          })
          this.getOrderAccessorials();
          alertMessage(I18n.t('messages.saved'))
        } else {
          this.setState({
            inProgress: false,
            errors: result.errors || [],
          })
        }
      })
    }
    
  }

  renderSlotObject = (
    paramDefinition,
    accountAccComponent,
    accessorial,
    orderAccObject,
    currentParamObj,
  ) => {
    const slotConfig = this.state.slotConfig;
    const size = 'small'
    const slotObject = slotConfig[accessorial.id];
    const paramValues = slotObject && slotObject.length > 0 && slotObject[0].param_values && slotObject[0].param_values.length > 0 ? slotObject[0].param_values : {};
    const slotConfigObjects = paramValues.length > 0 ? paramValues[0].expedite_sharp : []
    const isLos =
      !isEmpty(orderAccObject) && orderAccObject.accessorial_type === 'los'
    const isDisabled = isEmpty(orderAccObject) || isLos;
    const value = orderAccObject?.driver_pay_params && 
              orderAccObject.driver_pay_params?.time_slots ? 
              `${orderAccObject.driver_pay_params.time_slots.from_time}-${orderAccObject.driver_pay_params.time_slots.to_time}` : 
              "";
    return (
      <Fragment>
        {
        slotConfigObjects &&
        <Fragment>
          <Radio.Group
            value={value}
            onChange={e => this.handleOnChange(accessorial.id, 'time_slots', e.target.value, "")}
            disabled={isDisabled}
            size={size}
          >
            {
              slotConfigObjects.map(slot => (<Radio value={`${slot.from_time}-${slot.to_time}`}>{getSlotInfo(slot, this.props.isMilitaryTime)}</Radio>))
            }
          </Radio.Group>
        </Fragment>
        }
      </Fragment>
    )
  }


  renderField = (
    paramDefinition,
    accountAccComponent,
    accessorial,
    orderAccObject,
    currentParamObj,
  ) => {
    const size = 'small'
    const isLos =
      !isEmpty(orderAccObject) && orderAccObject.accessorial_type === 'los'
    const isDisabled = isEmpty(orderAccObject) || isLos;
    const { decimalPoints } = this.state;
    switch (paramDefinition.data_type) {
      case 'string':
        return (
          <Row>
            <Col>
              {paramDefinition.code === 'charge_type' ? (
                <Radio.Group
                  value={currentParamObj && currentParamObj.accessorial_value}
                  onChange={(e) =>
                    this.handleOnParamChange(
                      paramDefinition.code,
                      accountAccComponent.component_code,
                      accessorial.id,
                      e.target.value,
                    )
                  }
                  disabled={isDisabled}
                  size={size}
                >
                  <Radio value="percentage">Percentage</Radio>
                  <Radio value="flat">Flat</Radio>
                </Radio.Group>
              ) : paramDefinition.code === 'charge_value' ? (
                <Input
                  type="number"
                  addonBefore={
                    currentConfiguration[component.code].charge_type ===
                    'flat' ? (
                      <Icon type="dollar" />
                    ) : null
                  }
                  addonAfter={
                    currentConfiguration[component.code].charge_type ===
                    'percentage' ? (
                      <Icon type="percentage" />
                    ) : null
                  }
                  value={currentConfiguration[component.code][param.code]}
                  onChange={(e) =>
                    this.handleOnParamChange(
                      component.code,
                      param.code,
                      e.target.value,
                    )
                  }
                  className="textUpperCase"
                  min={0}
                  precision={decimalPoints}
                  defaultValue={0}
                  size={size}
                  disabled={isDisabled}
                />
              ) : (
                <Input
                  size={size}
                  value={currentParamObj && currentParamObj.accessorial_value}
                  onChange={(e) =>
                    this.handleOnParamChange(
                      paramDefinition.code,
                      accountAccComponent.component_code,
                      accessorial.id,
                      e.target.value,
                    )
                  }
                  disabled={isDisabled}
                />
              )}
            </Col>
          </Row>
        )
      case 'number':
        return (
          <Row>
            <Col>
              <Input
                type="number"
                size={size}
                value={currentParamObj && currentParamObj.accessorial_value}
                onChange={(e) =>
                  this.handleOnParamChange(
                    paramDefinition.code,
                    accountAccComponent.component_code,
                    accessorial.id,
                    e.target.value,
                  )
                }
                disabled={isDisabled}
                min={0}
                precision={
                  paramDefinition.code === 'min_charge' ||
                  paramDefinition.code === 'max_charge'
                    ? decimalPoints
                    : 0
                }
              />
            </Col>
          </Row>
        )
      case 'price':
        return (
          <Row>
            <Col>
              <Input
                type="number"
                size={size}
                value={currentParamObj && currentParamObj.accessorial_value}
                onChange={(e) =>
                  this.handleOnParamChange(
                    paramDefinition.code,
                    accountAccComponent.component_code,
                    accessorial.id,
                    e.target.value,
                  )
                }
                addonBefore={<Icon type="dollar" />}
                disabled={isDisabled}
                min={0}
                precision={decimalPoints}
              />
            </Col>
          </Row>
        )
      case 'Boolean':
        return (
          <Row>
            <Radio.Group
              value={currentParamObj && currentParamObj.accessorial_value}
              onChange={(e) =>
                this.handleOnParamChange(
                  paramDefinition.code,
                  accountAccComponent.component_code,
                  accessorial.id,
                  e.target.value,
                )
              }
              size={size}
              disabled={isDisabled}
            >
              <Radio value>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Row>
        )
      case 'Select':
        return (
          <Select
            showSearch
            size={size}
            value={currentParamObj && currentParamObj.accessorial_value}
            style={{ width: '100%' }}
            placeholder="Select"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onChange={(e) =>
              this.handleOnParamChange(
                paramDefinition.code,
                accountAccComponent.component_code,
                accessorial.id,
                e,
              )
            }
            disabled={isDisabled}
          >
            {paramDefinition.options.map((qoption, qindex) => (
              <Select.Option
                key={qoption.option_key}
                value={qoption.option_key}
              >
                {qoption.option_value}
              </Select.Option>
            ))}
          </Select>
        )
      default:
        break
    }
  }

  handleAuthorizeChange = (code, element, value) => {
    const authorizeDetails = this.state.authorizeDetails ? _.cloneDeep(this.state.authorizeDetails): {};
    if(isEmpty(authorizeDetails[code])){
      authorizeDetails[code] = {};
    }
    authorizeDetails[code][element] = value;
    this.setState({
      authorizeDetails,
    })
  }

  render() {
    const {
      accountComponentAccessorial,
      orgComponents,
      orderAccessorials,
      displayComponents,
      authorizeDetails
    } = this.state
    const losAccessorials = this.state.orderAccessorials
      ? this.state.orderAccessorials
          .filter((accessorial) => accessorial.accessorial_type === 'los')
          .map((accessorial) => accessorial.account_accessorial_id)
      : []
    const predefinedAccessorial = accountComponentAccessorial.filter(
      (accessorial) => losAccessorials.includes(accessorial.id),
    )
    const additionalAccessorial = accountComponentAccessorial.filter(
      (accessorial) => !losAccessorials.includes(accessorial.id),
    )
    return (
      <Spin
        spinning={this.state.inProgress}
        delay={1000}
        className="accessorial_block"
      >
        <div>
          {accountComponentAccessorial.length > 0 && (
            <Fragment>
              {predefinedAccessorial.length > 0 && (
                <div style={{ marginTop: '1rem' }}>
                  <h3>{I18n.t('accessorials.pre_selected')}</h3>
                  <OrderAccessorialRowList
                    data={predefinedAccessorial}
                    renderField={this.renderField}
                    orderAccessorials={this.state.orderAccessorials}
                    handleAddOrderCompAccessorial={
                      this.handleAddOrderCompAccessorial
                    }
                    handleAddOrderCompStandardAccessorial={
                      this.handleAddOrderCompStandardAccessorial
                    }
                    handleCheckStandardAccessorial={
                      this.handleCheckStandardAccessorial
                    }
                    onChange={
                      this.handleOnChange
                    }
                    displayComponents={displayComponents}
                    size="small"
                    pagination={{ position: 'none' }}
                    technicians={this.state.technicians}
                    isReadOnly
                    drivers={[]}
                    renderSlotObject={this.renderSlotObject}
                    handleAuthorizeChange={this.handleAuthorizeChange}
                    authorizeDetails={authorizeDetails}
                    required_accessorials={this.props.required_accessorials || []}
                    haveMultipleLocations={this.props.haveMultipleLocations}
                  />
                </div>
              )}
              {additionalAccessorial.length > 0 && (
                <div style={{ marginTop: '1rem' }}>
                  <Row>
                    <Col xs={12}>
                  <h3>{I18n.t('accessorials.additional')}</h3>
                    </Col>
                    <Col xs={12}>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '20px',
                        marginBottom: '10px',
                        alignItems: 'center'
                      }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                          <img src={masterRateSheet} alt="Master Accessorial" style={{ width: 20, height: 20 }} />
                          <span>Master Accessorial</span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                          <img src={accLevel} alt="Account Accessorial" style={{ width: 20, height: 20 }} />
                          <span>Account Accessorial</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <OrderAccessorialRowList
                    data={additionalAccessorial}
                    renderField={this.renderField}
                    orderAccessorials={this.state.orderAccessorials}
                    handleAddOrderCompAccessorial={
                      this.handleAddOrderCompAccessorial
                    }
                    onChange={
                      this.handleOnChange
                    }
                    handleAddOrderCompStandardAccessorial={
                      this.handleAddOrderCompStandardAccessorial
                    }
                    handleCheckStandardAccessorial={
                      this.handleCheckStandardAccessorial
                    }
                    displayComponents={displayComponents}
                    size="small"
                    pagination={{ position: 'none' }}
                    isReadOnly={false}
                    technicians={this.state.technicians}
                    drivers={this.state.drivers}
                    renderSlotObject={this.renderSlotObject}
                    config={this.props.config}
                    handleAuthorizeChange={this.handleAuthorizeChange}
                    authorizeDetails={authorizeDetails}
                    required_accessorials={this.props.required_accessorials || []}
                    haveMultipleLocations={this.props.haveMultipleLocations}

                  />
                </div>
              )}
              {FormErrors(this.state.errors)}
              {
                // ['NEW', ...AppConfig.notStartedList, 'PREPLAN'].includes(customerDetails.status) &&
                <Row className="marginTop10">
                  <Col xs={24} className="alignCenter">
                    {FormButtons(
                      this.state.inProgress,
                      this.handleSave,
                      this.props.onCancel,
                      true,
                      true,
                      'small',
                    )}
                  </Col>
                </Row>
              }
            </Fragment>
          )}
        </div>
      </Spin>
    )
  }
}

export default OrderAccAccessorial

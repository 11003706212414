import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Icon, Popconfirm, Button, Popover, Tooltip } from "../../common/UIComponents";
import OptingAppointment from "./OptingAppointment";
import { checkServiceExistance, isProdEnvironment } from "../../helpers/common";
import PrintLabels from "./PrintLabels";
import CloneOrders from "./CloneOrders";
import { withRouter } from "react-router";
import { WarehouseContext } from "../../context/WarehouseContext";
import { OrgContext } from "../../context/OrgContext";
import BulkLinehaulForm from "./BulkLinehaulForm";
import I18n from "../../common/I18n";
import SwitchOrder from "./SwitchOrder";
import AssignOrders from "./AssignOrders";
import { OrderConfigContext } from "../../context/OrderConfigContext";
//import { validateBulkActions } from "./helpers";
import SelectedOrdersList from "./SelectedOrdersList";
import { clearAGGridCells } from "../../helpers/ag_grid_functions";
import { AssignIcon, ExcelIcon, RocketIcon } from "../../common/CustomIcon";
import { downloadConsolidatedBols } from "../../api/OrdersApi";
import { base64ToPdf, isEmpty } from "../../common/Common";


const OrderBulkOperationContainer = React.memo((props) => {
  const {
    handleBolDownload,
    handleCancel,
    selectedOrders,
    resetOrders,
    filter,
    warehouseFilter,
    organizationSettings,
    handleMultipleOrdersStatusChange,
    handleReceiveToConfirm,
    changeStatus,
    statusChangeModal,
    handleEditOrder,
    handleBarCodePrint,
    handleBolClicked,
    showBarCodeModal,
    creditsExceededAccnts,
    getOrders,
    setConfirmBulkDelete,
    setChangeDispatchStatus,
    confirmBulkDelete,
    handleExportToExcel,
    isModalVisible,
    loading,
    refreshCallback,
    userContext,
    currentOrg, currentGridRef
  } = props;
  const {currentUser, updateScreenConfig} = userContext;
  const { getWHDetails  } = warehouseFilter
  const [hoveredCol, setHoveredCol] = useState(null);
  const [isChildContainerClicked, setIsChildContainerClicked] = useState(false);
  const [isPrintLabelClicked, setIsPrintLabelClicked] = useState(false);
  const [isCloneClicked, setIsCloneClicked] = useState(false);
  const [isChangeStatusClicked, setIsChangeStatusClicked] = useState(false);
  const [isBarcodeClicked, setIsBarcodeClicked] = useState(false);
  const [isBolClicked, setIsBolClicked] = useState(false);
  const [isLinehaulClicked, setIsLinehaulClicked] = useState(false);
  const [isSwitchOrderClicked, setIsSwitchOrderClicked] = useState(false);
  const [isAssignClicked, setIsAssignClicked] = useState(false);
  const [isExportClicked, setIsExportClicked] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [errorOrders, setErrorOrders] = useState([]);
  const isProdEnv = isProdEnvironment();
  const currentWh = getWHDetails()
  const { orderSelectedInfo, 
    setOrderSelectedInfo , statusForActions = {} } = useContext(OrderConfigContext)
  const showAssignIcon  = organizationSettings?.quick_dispatch_setting === "true" ? true : false;
  const selectedKeys = orderSelectedInfo.map(order => order.id)
  const handleClick = () => {
    setIsChildContainerClicked(true);
  };
  const hasMultiDriverDelivery = orderSelectedInfo.some(order => order.is_multi_driver_delivery);
  const actionWiseStatus = {
    "SCHEDULE":  !orderSelectedInfo.some(rec => !statusForActions['SCHEDULE'].status.includes(rec.status)),
    "MOVE_TO_RECEIVED":  !orderSelectedInfo.some(rec => !statusForActions['MOVE_TO_RECEIVED'].status.includes(rec.status)),
    "QUICK_DISPATCH":  !orderSelectedInfo.some(rec =>!statusForActions['QUICK_DISPATCH'].status.includes(rec.status)) && !hasMultiDriverDelivery,
    "MOVE_TO_VERIFY":  !orderSelectedInfo.some(rec =>!statusForActions['MOVE_TO_VERIFY'].status.includes(rec.status)),
    "CHANGE_STATUS":   !orderSelectedInfo.some(rec => statusForActions['CHANGE_STATUS'].status.includes(rec.status)),
    "PRINT_LABELS":  !orderSelectedInfo.some(rec => statusForActions['PRINT_LABELS'].status.includes(rec.status)),
    "CLONE_ORDERS":  !orderSelectedInfo.some(rec => !statusForActions['CLONE_ORDERS'].status.includes(rec.status)),
    "LINEHAUL":  !orderSelectedInfo.some(rec => !statusForActions['LINEHAUL'].status.includes(rec.status)),
    "SWITCH_ORDERS":  !orderSelectedInfo.some(rec => !statusForActions['SWITCH_ORDERS'].status.includes(rec.status)),
    "BULK_DELETE":  !orderSelectedInfo.some(rec => !statusForActions['BULK_DELETE'].status.includes(rec.status)),
    "DISPATCH_STATUS": !orderSelectedInfo.some(rec => !statusForActions['DISPATCH_STATUS'].status.includes(rec.status)),
  }

  const renderActionTooltipTitle = (action, haveAccess, isIncludes = true) => {
    if (action === 'QUICK_DISPATCH' && hasMultiDriverDelivery) {
      return "Multi-Man jobs cannot be assigned via Quick Dispatch.";
    }
    return haveAccess ? undefined : 
    isIncludes ? 
    `Orders with status ${statusForActions[action].status.join(',')} are only allowed to perform this action. Please remove other orders to proceed` : 
    `Orders status without ${statusForActions[action].status.join(',')} are allowed  to perform this action.` ;

  }


  const columnStyle = (haveAccess) => {
    return (haveAccess ? {
        cursor:  "pointer",
        padding: "0px 10px",
        opacity: 1,
    } : {
        cursor:  'not-allowed',
        padding: "0px 10px",
        opacity:  0.5,
    })
  }
  const validateBulkActions = (
    records = [],
    action = "",
    cb = null,
   // errorCallback = null,
    isIncludes = true,
  ) => {
    const checkValidation = (allowedStatuses) => {
      return isIncludes ? 
      records.filter((rec) => !allowedStatuses.includes(rec.status)).map(rec => rec.customer_order_number) : 
      records.filter((rec) => allowedStatuses.includes(rec.status)).map(rec => rec.customer_order_number)
    }
    if(!isEmpty(action)){
      let result = []
      let allowedStatuses = []
      switch (action) {
        case "SCHEDULE":
          allowedStatuses = statusForActions['SCHEDULE'].status;
          result = checkValidation(allowedStatuses);
          break;
        case "MOVE_TO_RECEIVED":
          allowedStatuses = statusForActions['MOVE_TO_RECEIVED'].status;
          result = checkValidation(allowedStatuses);
          break;
        case "QUICK_DISPATCH":
          allowedStatuses = statusForActions['QUICK_DISPATCH'].status;
          result = checkValidation(allowedStatuses);
          break;
        case "MOVE_TO_VERIFY":
          allowedStatuses = statusForActions['MOVE_TO_VERIFY'].status;
          result = checkValidation(allowedStatuses);
          break;
        case "CHANGE_STATUS":
          allowedStatuses = statusForActions['CHANGE_STATUS'].status;
          result = checkValidation(allowedStatuses);
          break;
        case "PRINT_LABELS":
          allowedStatuses = statusForActions['PRINT_LABELS'].status;
          result = checkValidation(allowedStatuses);
          break;
        case "CLONE_ORDERS":
          allowedStatuses = statusForActions['CLONE_ORDERS'].status;
          result = checkValidation(allowedStatuses);
          break;
        case "LINEHAUL":
          allowedStatuses = statusForActions['LINEHAUL'].status;
          result = checkValidation(allowedStatuses);
          break;
        case "SWITCH_ORDERS":
          allowedStatuses = statusForActions['SWITCH_ORDERS'].status;
          result = checkValidation(allowedStatuses);
          break;
        case "BULK_DELETE":
          allowedStatuses = statusForActions['BULK_DELETE'].status;
          result = checkValidation(allowedStatuses);
          break;
        case "DISPATCH_STATUS":
          allowedStatuses = statusForActions['DISPATCH_STATUS'].status;
          result = checkValidation(allowedStatuses);
          break;
        default:
          break;
      }
      if(result.length === 0){
        cb();
      } else {
        if(isIncludes){
          handleErrors([`${result.join(', ')} cannot be processed. Only orders with status ${allowedStatuses.join(',')}  are allowed.`])
        } else{
          handleErrors(result, allowedStatuses, isIncludes)
        }
        
        // if(errorCallback){
        //   handleErrors(result)
        // } else {
        //   renderAlertMessage(`${result.join(',')} are not allowed`)
        // }
      }
    }
    
  };

  
  // const [operationFinished, setOperationFinished] = useState(false);

  // useEffect(() => {
  //   if (operationFinished) {
  //     handleCancel();
  //     setOperationFinished(false);
  //   }
  // }
  // , [operationFinished]);
  const  handleErrors = (errors) => {
    setErrorOrders(errors)
  }

  const popoverContent = (
    <div style={{ width: 300 }} className="desc-container">
      <h4 style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold' }}>
        <span>New Feature! Quick Assign</span>
        <span className="icon">
          <RocketIcon className="alignRight" />
        </span>
      </h4>
      <p>
        You can now quickly assign orders to drivers from the orders screen
        without accessing preplan.
      </p>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button
          className="link-button"
          onClick={(e) => {
            e.preventDefault();
            setPopoverVisible(false);
            updateScreenConfig("order_listing", false, "show_quick_dispatch_feature_hint")
          }}
        >
          Dismiss
        </button>
      </div>
    </div>
  );

  useEffect(() => {
    if (!statusChangeModal) {
      setIsChangeStatusClicked(false);
    }
  }, [statusChangeModal]);

  useEffect(() => {
    if (!showBarCodeModal) {
      setIsBarcodeClicked(false);
    }
  }, [showBarCodeModal]);

  useEffect(() => {
    if (!isModalVisible) {
      setIsExportClicked(false);
    }
  }, [isModalVisible]);

  useEffect(() => {
    const showMessage = _.get(
      currentUser,
      `screen_config.order_listing.show_quick_dispatch_feature_hint`,
      true
    )
    if(showMessage){
      setTimeout(() => {
          setPopoverVisible(true);
      }, 300);
    }
  }, []);


  return (
    <div className="app-footer-container" style={{opacity: showAssignIcon && popoverVisible ? 0.65 : null}} onClick={()=> setPopoverVisible(false)}>
      {Object.keys(statusForActions).length > 0 && <Row
        className="app-footer"
        type="flex"
        gutter={18}
        style={{ color: "#617AD6", fontWeight: "bold" }}
      >
        <Col className="alignLeft" style={{ color: "#444C52" }}>
          <Button
            type="primary"
            className="clearButtonStyle"
            style={{ marginTop: 15, marginLeft: 0 }}
            onClick={handleCancel}
            icon="close"
            shape="circle"
            size="large"
          />{" "}
          &nbsp;&nbsp;
          <SelectedOrdersList 
            organizationSettings={props.organizationSettings}
            errorOrders={errorOrders} 
            setErrorOrders={setErrorOrders} 
            currentGridRef={currentGridRef}
            userContext={userContext}
          /> 
          {/* {`${orderSelectedInfo.length} Orders`}&nbsp;&nbsp;&nbsp;&nbsp; */}
        </Col>
        {checkServiceExistance("SBA") &&
          //["NEW", "RECEIVED", "VERIFIED", "ON_HOLD"].includes(filter) && 
          (
            <Col
              align="middle"
              justify="center"
              style={{...columnStyle(actionWiseStatus['SCHEDULE'])}}
              className={
                hoveredCol === 1 || isChildContainerClicked
                  ? "footer-hovered-col"
                  : ""
              }
              onMouseEnter={() => setHoveredCol(1)}
              onMouseLeave={() => setHoveredCol(null)}
              //onClick={handleClick}
              onClick={() => validateBulkActions(
                orderSelectedInfo,
                'SCHEDULE',
                () => {handleClick()}
              )}
            >
            <Tooltip title={renderActionTooltipTitle('SCHEDULE',actionWiseStatus['SCHEDULE'], statusForActions['SCHEDULE'].isIncludes)}>
              <Row type="flex" align="middle" justify="center">
                <Col>
                  <Icon
                    type="calendar"
                    style={{ fontSize: 20, marginTop: 13 }}
                  />
                </Col>
              </Row>
              <Row type="flex" align="middle" justify="center">
                <Col>
                  <OptingAppointment
                    resetOrders={() => resetOrders(true)}
                    orders={selectedKeys || []}
                    filter={filter}
                    warehouseFilter={warehouseFilter}
                    organizationSettings={organizationSettings}
                    selectedOrders={orderSelectedInfo || []}
                    showSelectedOrder={true}
                    isFromBulkContainer={true}
                    isChildContainerClicked={isChildContainerClicked}
                    setIsChildContainerClicked={setIsChildContainerClicked}
                    setIsHovered={setHoveredCol}
                    showSchduleOption={true}
                    resetSelection={handleCancel}
                  />
                </Col>
              </Row>
            </Tooltip>
            </Col>
          )}
        {checkServiceExistance("COSU") &&
        // filter === "NEW" && 
          (
          <Col
            align="middle"
            justify="center"
            className={hoveredCol === 2 ? "footer-hovered-col" : ""}
            onMouseEnter={() => setHoveredCol(2)}
            onMouseLeave={() => setHoveredCol(null)}
            style={{...columnStyle(actionWiseStatus['MOVE_TO_RECEIVED'])}}
           // onClick={() => handleMultipleOrdersStatusChange("RECEIVED")}
            onClick={() =>
              actionWiseStatus['MOVE_TO_RECEIVED'] ?
                validateBulkActions(
                  orderSelectedInfo,
                  'MOVE_TO_RECEIVED',
                  () => { handleMultipleOrdersStatusChange("RECEIVED") }
                )
                : () => { }
            }
          >
          <Tooltip title={renderActionTooltipTitle('MOVE_TO_RECEIVED', actionWiseStatus['MOVE_TO_RECEIVED'], statusForActions['MOVE_TO_RECEIVED'].isIncludes)}>
            <Row type="flex" align="middle" justify="center">
              <Col>
                <Icon
                  type="check-square"
                  style={{ fontSize: 20, marginTop: 13 }}
                />
              </Col>
            </Row>
            <Row type="flex" align="middle" justify="center">
              <Col>Mark Received</Col>
            </Row>
          </Tooltip>
          </Col>
        )}
        {showAssignIcon && checkServiceExistance("COSU") &&
         // ["NEW", "RECEIVED", "VERIFIED"].includes(filter) && 
        (
            <Popover
                  content={popoverContent}
                  title={null}
                  overlayStyle={{ maxWidth: 500 }}
                  placement="topLeft"
                  overlayClassName="custom-popover"
                  visible={popoverVisible}

                >
              <Col
                align="middle"
                justify="center"
                className={
                  hoveredCol === 11 || isAssignClicked ? "footer-hovered-col" : ""
                }
                onMouseEnter={() => setHoveredCol(11)}
                onMouseLeave={() => setHoveredCol(null)}
                style={{...columnStyle(actionWiseStatus['QUICK_DISPATCH'])}}
                // onClick={() => setIsAssignClicked(true)}
                onClick={() => {
                  if (hasMultiDriverDelivery) {
                    return; 
                  }
                  validateBulkActions(orderSelectedInfo,
                  'QUICK_DISPATCH',
                  () => { setIsAssignClicked(true)})
                }}
              >
              <Tooltip title={renderActionTooltipTitle('QUICK_DISPATCH', actionWiseStatus['QUICK_DISPATCH'], statusForActions['QUICK_DISPATCH'].isIncludes)}>
                <Row type="flex" align="middle" justify="center">
                  <Col>
                    <AssignIcon
                      style={{ fontSize: 20, marginTop: 9 }}
                      color="#617AD6"
                    />
                  </Col>
                </Row>
                <Row type="flex" align="middle" justify="center">
                  <Col>
                    <AssignOrders
                      selectedOrders={orderSelectedInfo}
                      isFromBulkContainer={true}
                      isAssignClicked={isAssignClicked}
                      setIsAssignClicked={setIsAssignClicked}
                      setIsHovered={setHoveredCol}
                      currentOrg={currentOrg}
                      organizationSettings={props.organizationSettings}
                      setOrderSelectedInfo={setOrderSelectedInfo}
                      orderRefreshCall={() => {
                        setOrderSelectedInfo([])
                        clearAGGridCells(currentGridRef)
                        refreshCallback()
                      }}
                    />
                  </Col>
                </Row>
              </Tooltip>
              </Col>
            </Popover>
          )}
        {checkServiceExistance("CAMV") && 
        // filter === "RECEIVED" && 
        (
          <Col
            align="middle"
            justify="center"
            className={hoveredCol === 3 ? "footer-hovered-col" : ""}
            onMouseEnter={() => setHoveredCol(3)}
            onMouseLeave={() => setHoveredCol(null)}
            style={{...columnStyle(actionWiseStatus['MOVE_TO_VERIFY'])}}
            // onClick={() => handleReceiveToConfirm()}
            onClick={() => 
              actionWiseStatus['MOVE_TO_VERIFY'] ?
                validateBulkActions(
                  orderSelectedInfo,
                  'MOVE_TO_VERIFY',
                  () => { handleReceiveToConfirm() }
                )
                : () => { }
            }
          >
           <Tooltip title={renderActionTooltipTitle('MOVE_TO_VERIFY',actionWiseStatus['MOVE_TO_VERIFY'], statusForActions['MOVE_TO_VERIFY'].isIncludes)}>
              <Row type="flex" align="middle" justify="center">
                <Col>
                  <Icon
                    type="check-circle"
                    style={{ fontSize: 20, marginTop: 13 }}
                  />
                </Col>
              </Row>
              <Row type="flex" align="middle" justify="center">
                <Col>Mark Verified</Col>
              </Row>
            </Tooltip>
          </Col>
        )}
        {checkServiceExistance("COSU") &&
          !["PENDING", "REJECTED"].includes(filter) && 
          (
            <Col
              align="middle"
              justify="center"
              className={
                hoveredCol === 4 || isChangeStatusClicked
                  ? "footer-hovered-col"
                  : ""
              }
              onMouseEnter={() => setHoveredCol(4)}
              onMouseLeave={() => setHoveredCol(null)}
              style={{...columnStyle(actionWiseStatus['CHANGE_STATUS'])}}
              // onClick={() => {
              //   changeStatus();
              //   setIsChangeStatusClicked(true);
              // }}
              onClick={() => validateBulkActions(orderSelectedInfo,
                'CHANGE_STATUS',
                () => { 
                  changeStatus();
                  setIsChangeStatusClicked(true);
                },
                statusForActions['CHANGE_STATUS'].isIncludes)}
            >
            <Tooltip title={renderActionTooltipTitle('CHANGE_STATUS', actionWiseStatus['CHANGE_STATUS'], statusForActions['CHANGE_STATUS'].isIncludes)}>
              <Row type="flex" align="middle" justify="center">
                <Col>
                  <Icon type="flag" style={{ fontSize: 20, marginTop: 13 }} />
                </Col>
              </Row>
              <Row type="flex" align="middle" justify="center">
                <Col> Change Status</Col>
              </Row>
            </Tooltip>
            </Col>
          )}
        <Col
          align="middle"
          justify="center"
          className={
            hoveredCol === 13 || isChangeStatusClicked
              ? "footer-hovered-col"
              : ""
          }
          onMouseEnter={() => setHoveredCol(13)}
          onMouseLeave={() => setHoveredCol(null)}
          style={{ ...columnStyle(actionWiseStatus['DISPATCH_STATUS']), marginRight: 12 }}
          // onClick={setConfirmBulkDelete}
          onClick={() => validateBulkActions(orderSelectedInfo,
            'DISPATCH_STATUS',
            () => {
              setChangeDispatchStatus(true)
            })}
        >
          {/* <Tooltip title={renderActionTooltipTitle('CHANGE_STATUS', actionWiseStatus['CHANGE_STATUS'], statusForActions['CHANGE_STATUS'].isIncludes)}> */}
          <Row type="flex" align="middle" justify="center">
            <Col>
              <Icon type="bg-colors" style={{ fontSize: 20, marginTop: 13 }} />
            </Col>
          </Row>
          <Row type="flex" align="middle" justify="center">
            <Col> Dispatch Status</Col>
          </Row>
          {/* </Tooltip> */}
        </Col>
        {!["PENDING", "REJECTED"].includes(filter) && (
          <Col
            align="middle"
            justify="center"
            className={
              hoveredCol === 5 || isPrintLabelClicked
                ? "footer-hovered-col"
                : ""
            }
            onMouseEnter={() => setHoveredCol(5)}
            onMouseLeave={() => setHoveredCol(null)}
            style={{...columnStyle(actionWiseStatus['PRINT_LABELS'])}}
            // onClick={() => setIsPrintLabelClicked(true)}
            onClick={() => validateBulkActions(orderSelectedInfo,
              'PRINT_LABELS',
              () => { 
                setIsPrintLabelClicked(true)
              },
              statusForActions['CHANGE_STATUS'].isIncludes)
            }
          >
            <Tooltip title={renderActionTooltipTitle('PRINT_LABELS', actionWiseStatus['PRINT_LABELS'], statusForActions['PRINT_LABELS'].isIncludes)}>

            <Row type="flex" align="middle" justify="center">
              <Col>
                <Icon type="download" style={{ fontSize: 20, marginTop: 13 }} />
              </Col>
            </Row>
            <Row type="flex" align="middle" justify="center">
              <Col>
                <PrintLabels
                  className=""
                  buttonType={null}
                  label={I18n.t("order.download_labels")}
                  isDisabled={selectedKeys.length === 0}
                  handleSuccess={() => {}}
                  orderIds={[...selectedKeys]}
                  size={null}
                  buttonIcon={null}
                  isFromBulkContainer={true}
                  isPrintLabelClicked={isPrintLabelClicked}
                  setIsPrintLabelClicked={setIsPrintLabelClicked}
                  setIsHovered={setHoveredCol}
                />
              </Col>
            </Row>
            </Tooltip>
          </Col>
        )}
        {checkServiceExistance("SBA") && (
          <Col
            align="middle"
            justify="center"
            className={
              hoveredCol === 12 || isExportClicked ? "footer-hovered-col" : ""
            }
            onMouseEnter={() => setHoveredCol(12)}
            onMouseLeave={() => setHoveredCol(null)}
            style={{
              cursor: "pointer",
              padding: "0px 10px",
            }}
            onClick={() => {
              handleExportToExcel();
              setIsExportClicked(true);
            }}
          >
            <Row type="flex" align="middle" justify="center">
              <Col>
                <ExcelIcon
                  style={{ fontSize: 20, marginTop: 9 }}
                  color="#617AD6"
                />
              </Col>
            </Row>
            <Row type0="flex" align="middle" justify="center">
              <Col>Export</Col>
            </Row>
          </Col>
        )}
        {checkServiceExistance("SBA") &&
         // ["NEW", "RECEIVED", "VERIFIED", "ON_HOLD"].includes(filter) && 
        (
            <Col
              align="middle"
              justify="center"
              className={
                hoveredCol === 6 || isCloneClicked ? "footer-hovered-col" : ""
              }
              onMouseEnter={() => setHoveredCol(6)}
              onMouseLeave={() => setHoveredCol(null)}
              style={{...columnStyle(actionWiseStatus['CLONE_ORDERS'])}}
              // onClick={() => setIsCloneClicked(true)}
              onClick={() => validateBulkActions(orderSelectedInfo,
                'CLONE_ORDERS',
                () => { 
                  setIsCloneClicked(true)
                })}
            >
            <Tooltip title={renderActionTooltipTitle('CLONE_ORDERS', actionWiseStatus['CLONE_ORDERS'], statusForActions['PRINT_LABELS'].isIncludes)}>
              <Row type="flex" align="middle" justify="center">
                <Col>
                  <Icon type="copy" style={{ fontSize: 20, marginTop: 13 }} />
                </Col>
              </Row>
              <Row type="flex" align="middle" justify="center">
                <Col>
                  <CloneOrders
                    resetOrders={() => resetOrders(true)}
                    orders={selectedKeys || []}
                    filter={filter}
                    handleEditOrder={handleEditOrder}
                    warehouseFilter={warehouseFilter}
                    organizationSettings={organizationSettings}
                    selectedOrders={orderSelectedInfo || []}
                    showSelectedOrder={true}
                    isFromBulkContainer={true}
                    isCloneClicked={isCloneClicked}
                    setIsCloneClicked={setIsCloneClicked}
                    setIsHovered={setHoveredCol}
                    resetSelection={handleCancel}
                  />
                </Col>
              </Row>
              </Tooltip>
            </Col>
          )}

        <Popconfirm
          title="Are you sure you want to download the consolidated BOL?"
          onConfirm={handleBolDownload}
          okText="Yes"
          cancelText="No"
          disabled={loading}
        >
          <Col
            align="middle"
            justify="center"
            className={hoveredCol === 11 || isBolClicked ? "footer-hovered-col" : ""}
            onMouseEnter={() => setHoveredCol(11)}
            onMouseLeave={() => setHoveredCol(null)}
            style={{
              cursor: loading ? "not-allowed" : "pointer",
              padding: "0px 10px",
              marginRight: filter === "ASSIGNED" ? 12 : null,
              opacity: loading ? 0.5 : 1,
            }}
          >
            <Row type="flex" align="middle" justify="center">
              <Col>
                <Icon
                  type={loading ? "loading" : "file-done"}
                  style={{ fontSize: 20, marginTop: 13 }}
                />
              </Col>
            </Row>
            <Row type="flex" align="middle" justify="center">
              <Col>BOL</Col>
            </Row>
          </Col>
        </Popconfirm>



        {checkServiceExistance("SBA") && (
          <Col
            align="middle"
            justify="center"
            className={
              hoveredCol === 7 || isBarcodeClicked ? "footer-hovered-col" : ""
            }
            onMouseEnter={() => setHoveredCol(7)}
            onMouseLeave={() => setHoveredCol(null)}
            style={{
              cursor: "pointer",
              padding: "0px 10px",
              marginRight: filter === "ASSIGNED" ? 12 : null,
            }}
            onClick={() => {
              handleBarCodePrint(true);
              setIsBarcodeClicked(true);
            }}
          >
            <Row type="flex" align="middle" justify="center">
              <Col>
                <Icon type="printer" style={{ fontSize: 20, marginTop: 13 }} />
              </Col>
            </Row>
            <Row type="flex" align="middle" justify="center">
              <Col>Barcode</Col>
            </Row>
          </Col>
        )}
        {checkServiceExistance("SBA") &&
          // ["NEW", "RECEIVED", "VERIFIED"].includes(filter) && 
          (
            <Col
              align="middle"
              justify="center"
              className={
                hoveredCol === 8 || isLinehaulClicked
                  ? "footer-hovered-col"
                  : ""
              }
              onMouseEnter={() => setHoveredCol(8)}
              onMouseLeave={() => setHoveredCol(null)}
              style={{...columnStyle(actionWiseStatus['LINEHAUL'])}}
             // onClick={() => setIsLinehaulClicked(true)}
              onClick={() => validateBulkActions(orderSelectedInfo,
                'LINEHAUL',
                () => { 
                  setIsLinehaulClicked(true)
                })
              }
            >
            <Tooltip title={renderActionTooltipTitle('LINEHAUL', actionWiseStatus['LINEHAUL'], statusForActions['LINEHAUL'].isIncludes)}>

              <Row type="flex" align="middle" justify="center">
                <Col>
                  <Icon
                    type="gateway"
                    style={{ fontSize: 20, marginTop: 13 }}
                  />
                </Col>
              </Row>
              <Row type="flex" align="middle" justify="center">
                <Col>
                  <BulkLinehaulForm
                    resetOrders={() => resetOrders(true)}
                    orders={orderSelectedInfo || []}
                    filter={filter}
                    creditsExceededAccnts={creditsExceededAccnts}
                    handleEditOrder={handleEditOrder}
                    orderNumbersSelected={true}
                    warehouseFilter={warehouseFilter}
                    organizationSettings={organizationSettings}
                    isFromBulkContainer={true}
                    isLinehaulClicked={isLinehaulClicked}
                    setIsLinehaulClicked={setIsLinehaulClicked}
                    setIsHovered={setHoveredCol}
                  />
                </Col>
              </Row>
              </Tooltip>
            </Col>
          )}
        {checkServiceExistance("SBA") &&
        //  ["NEW", "RECEIVED", "VERIFIED"].includes(filter) && 
        (
            <Col
              align="middle"
              justify="center"
              className={
                hoveredCol === 9 || isSwitchOrderClicked
                  ? "footer-hovered-col"
                  : ""
              }
              onMouseEnter={() => setHoveredCol(9)}
              onMouseLeave={() => setHoveredCol(null)}
              style={{...columnStyle(actionWiseStatus['SWITCH_ORDERS'])}}
              //onClick={() => setIsSwitchOrderClicked(true)} 
              onClick={() => validateBulkActions(orderSelectedInfo,
                'SWITCH_ORDERS',
                () => { 
                  setIsSwitchOrderClicked(true)
                })
              }
            >
            <Tooltip title={renderActionTooltipTitle('SWITCH_ORDERS', actionWiseStatus['SWITCH_ORDERS'], statusForActions['LINEHAUL'].isIncludes)}>

              <Row type="flex" align="middle" justify="center">
                <Col>
                  <Icon type="swap" style={{ fontSize: 20, marginTop: 13 }} />
                </Col>
              </Row>
              <Row type="flex" align="middle" justify="center">
                <Col>
                  <SwitchOrder
                    selectedOrders={orderSelectedInfo}
                    refreshOrders={getOrders}
                    resetOrders={() => resetOrders(true)}
                    clearOrders={handleCancel}
                    isFromBulkContainer={true}
                    isSwitchOrderClicked={isSwitchOrderClicked}
                    setIsSwitchOrderClicked={setIsSwitchOrderClicked}
                    setIsHovered={setHoveredCol}
                    resetSelection={handleCancel}
                  />
                </Col>
              </Row>
            </Tooltip>
            </Col>
          )}



        {checkServiceExistance("COD") &&
          // [
          //   "NEW",
          //   "RECEIVED",
          //   "VERIFIED",
          //   "PENDING",
          //   "APPROVED",
          //   "REJECTED",
          //   "ON_HOLD",
          // ].includes(filter) && 
          (
            <Col
              align="middle"
              justify="center"
              className={
                hoveredCol === 10 || confirmBulkDelete
                  ? "delete-hovered-col textRed"
                  : ""
              }
              onMouseEnter={() => setHoveredCol(10)}
              onMouseLeave={() => setHoveredCol(null)}
              style={{...columnStyle(actionWiseStatus['BULK_DELETE']), marginRight: 12}}
              // onClick={setConfirmBulkDelete}
              onClick={() => validateBulkActions(orderSelectedInfo,
                'BULK_DELETE',
                () => { 
                  setConfirmBulkDelete()
                })}
              
            >
            <Tooltip title={renderActionTooltipTitle('BULK_DELETE', actionWiseStatus['BULK_DELETE'], statusForActions['BULK_DELETE'].isIncludes)}>
              <Row type="flex" align="middle" justify="center">
                <Col>
                  <Icon type="delete" style={{ fontSize: 20, marginTop: 13 }} />
                </Col>
              </Row>
              <Row type="flex" align="middle" justify="center">
                <Col>Delete</Col>
              </Row>
              </Tooltip>
            </Col>
          )}
      </Row>}
    </div>
  );
});
const OrderBulkOperationsContainerComponent = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const { orgSettings: organizationSettings, accountsExceeded, currentOrg } =
    useContext(OrgContext);
  return (
    <OrderBulkOperationContainer
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      creditsExceededAccnts={accountsExceeded}
      currentOrg={currentOrg}
      {...props}
    />
  );
});
OrderBulkOperationsContainerComponent.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  resetOrders: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  handleMultipleOrdersStatusChange: PropTypes.func.isRequired,
  handleReceiveToConfirm: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  refreshCallback: PropTypes.func.isRequired,
};
OrderBulkOperationsContainerComponent.defaultProps = {};
export default OrderBulkOperationsContainerComponent;
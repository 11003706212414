
import { Call, ApiUrl, PageParams, ParseGeneralResponse } from "./ApiUtils";
import AppConfig from "../config/AppConfig";
import { isEmpty } from "../common/Common";

export function fetchAccessorials(
  orgId,
  page = 1,
  perPage = AppConfig.perPage
) {
  const url = ApiUrl(
    `v2/accessorial?org_id=${orgId}&${PageParams(page, perPage)}`
  );
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: {
        accessorials: "accessorials",
        pagination: "meta.pagination",
      },
    },
    false
  );
}

export function fetchAllAccessorials(page = null, perPage = null) {
  const url = ApiUrl(
    `v2/accessorial/master_accessorial?${PageParams(page, perPage)}`
  );
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: {
        accessorials: "accessorial_master",
        pagination: "meta.pagination",
      },
    },
    false
  );
}

export function saveAccessorial(isNew, data) {
  const url = isNew
    ? ApiUrl("v2/accessorial")
    : ApiUrl(`v2/accessorial/${data.id}`);
  const method = isNew ? "post" : "put";
  return Call(
    method,
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { accessorials: "accessorials" } },
    false
  );
}

export function deleteAccessorial(id, data = {}) {
  data.operation_code = 'AAD'
  const url = ApiUrl(`v2/account_accessorials/${id}`);
  return Call("delete", url, data, ParseGeneralResponse, {}, false);
}

export function cloneAccountAccessorial(account_accessorial_id) {
  const url = ApiUrl("v2/account_accessorials/account_accessorial_clone?operation_code=AAAACL");
  const method = "post";
  return Call(
    method,
    url,
    { account_accessorial_id: account_accessorial_id },
    ParseGeneralResponse,
    { responseDataKeys: { accessorials: "" } },
    false
  );
}

export function saveAccountAccessorial(data) {
  data.operation_code = 'AAC';
  const url = ApiUrl("v2/account_accessorials");
  const method = "post";
  return Call(
    method,
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { accessorials: "" } },
    false
  );
}

export function updateAccountAccessorial(data) {
  const url = ApiUrl(`v2/account_accessorials/${data.id}`);
  const method = "put";
  return Call(
    method,
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { accessorials: "" } },
    false
  );
}

export function fetchAccessorialsParams(id) {
  const url = ApiUrl(
    `v2/account_accessorial_params?account_accessorial_id=${id}`
  );
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: { accessorials: "account_accessorials" },
    },
    false
  );
}

export function saveAccessorialParams(isNew, data) {
  // const url = isNew ? ApiUrl('v2/account_accessorial_params') : ApiUrl(`v2/account_accessorial_params/${data.id}`);
  // const method = isNew ? 'post' : 'put';
  // return Call(method, url, data, ParseGeneralResponse, { responseDataKeys: { accessorials: 'accessorials' } }, false);
  const url = ApiUrl("v2/account_accessorial_params");
  const method = "post";
  return Call(
    method,
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { accessorials: "accessorials" } },
    false
  );
}

export function saveAttemptsAccessorialParams(data) {
  const url = ApiUrl("v2/account_accessorial_params");
  const method = "post";
  return Call(
    method,
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { accessorials: "accessorials" } },
    false
  );
}

export function deleteAccessorialParams(data) {
  const url = ApiUrl(`v2/account_accessorial_params/${data.id}`);
  return Call("delete", url, data, ParseGeneralResponse, {}, false);
}

export function fetchAccessorialsComponents() {
  const url = ApiUrl("v2/account_accessorials/accessorial_components?operation_code=AAAC");
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: { components: "accessorial_components" },
    },
    false
  );
}

export function saveCustomAccessorial(isNew, data) {
  data.operation_code = isNew ? 'AACA' : 'AAU';
  const url = isNew
    ? ApiUrl("v2/account_accessorials/create_accessorial")
    : ApiUrl("v2/account_accessorials/create_accessorial/");
  const method = "post"; // isNew ? 'post' : 'put';
  return Call(
    method,
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { accessorials: "" } },
    false
  );
}

export const saveAccountFormAccessorials = (payload) => {
  let url = ApiUrl("v2/form_responses/form_accessorials");
  return Call(
    "post",
    url,
    payload,
    ParseGeneralResponse,
    {
      responseDataKeys: { data: "" },
    },
    false
  );
};

export const fetchSavedFormQuestions = (payload) => {
  const { formId, organizationId, accountId } = payload;

  let url = ApiUrl(
    `v2/form_responses/get_form_accessorials?form_id=${formId}&organization_id=${organizationId}&account_id=${accountId}`
  );
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: { data: "" },
    },
    false
  );
};


export function fetchAccountAccessorials(
  orgId,
  accountId,
  visibleTo = "BILLING",
  page = null,
  perPage = null,
  searchText = "",
  includeMasterAccessorials = "true"
) {
  let url = ApiUrl(
    `v2/account_accessorials/account_accessorial_list?organization_id=${orgId}&account_id=${accountId}&visible_to=${visibleTo}&operation_code=AAAAL&include_master_accessorials=${includeMasterAccessorials}`
  );
  if (!isEmpty(page) && !isEmpty(perPage)) {
    url = `${url}&${PageParams(page, perPage)}`;
  }
  if (!isEmpty(searchText)){
    url = `${url}&search_text=${searchText}`;
  }
    return Call(
      "get",
      url,
      {},
      ParseGeneralResponse,
      {
        responseDataKeys: {
          accessorials: "account_accessorials",
          pagination: "meta.pagination",
          rate_sheet_params: "rate_sheet_params",
        },
      },
      false
    );
}

export function fetchRateSheetAccessorials(rateSheetId, orgId, searchText = "") {
  let url = ApiUrl(`v2/account_accessorials/rate_sheet_accessorial_list?rate_sheet_id=${rateSheetId}&organization_id=${orgId}`);
  if (!isEmpty(searchText)) {
    url = `${url}&search_text=${searchText}`;
  }
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: { accessorials: "rate_sheet_accessorials", pagination: "meta.pagination" },
      },
      false
    );
}

export function fetchAccessorialDetails(id) {
  const url = ApiUrl(
    `v2/account_accessorials/account_accessorial_details?account_accessorial_id=${id}&operaion_code=AAAAD`
  );
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: { accessorial: "account_accessorial" },
    },
    false
  );
}

export function saveAccessorialConfig(data) {
  data.operation_code = 'AACAA';
  const url = ApiUrl(
    "v2/account_accessorial_params/create_account_accessorial"
  );
  const method = "post";
  return Call(
    method,
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { accessorials: "accessorials" } },
    false
  );
}

export function deleteWeightRange(data) {
  const url = ApiUrl("v2/account_accessorial_params/delete_weight_range");
  const method = "post";
  return Call(
    method,
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { accessorials: "accessorials" } },
    false
  );
}

export function getWarehouseAccessorials(data) {
  const url = ApiUrl(`v2/accessorial/warehouse_accessorials?account_id=${data}`);
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: { warehouseAccessorials: "warehouse_accessorials" },
    },
    false
  );
}

export function saveWarehouseAccessorials(data) {
  const url = ApiUrl(`v2/customer_orders/save_required_accessorials`);
  return Call(
    "post",
    url,
    data,
    ParseGeneralResponse,
    {
      responseDataKeys: { warehouseAccessorials: "warehouse_accessorials" },
    },
    false
  );
}
export function fetchCodeDetails(data) {
  const url = ApiUrl(`/v2/account_accessorials/get_edi_codes`);
  return Call("get", url, data, ParseGeneralResponse, {
    responseDataKeys: { data: "" },
  });
}

export function saveBillingAccessorial(data) {
  const url = ApiUrl("v2/billing/create_billing_accossorial");
  return Call(
    "post",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { accessorials: "accessorials" } },
    false
  );
}

export function saveManualBillingAccessorial(data) {
  const url = ApiUrl("v2/billing/manual_billing_accessorial");
  return Call(
    "post",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { accessorialcost: "accessorialcost" } },
    false
  );
}

export const updateAccessorialInvoiceLine = (data) => {
  console.log("data", data);
  const url = ApiUrl("v2/billing/update_accessorial_invoice_line");
  return Call(
    "put",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { accessorialcost: "accessorialcost" } },
    false
  );
}